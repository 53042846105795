import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, id } = action.payload
            state.user = user
            state.token = id
        },
        clearCredentials: () => initialState
    }
})
export const { setCredentials, clearCredentials } = authSlice.actions

export default authSlice.reducer