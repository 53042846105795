import classNames from "classnames"
import { Link } from "react-router-dom"
import { siteSettings } from "../../settings/site-settings"


const Logo = ({
    className,
    href = siteSettings.logo.href,
    ...props
  }) => {
    return(
        <Link to={href}
        className={classNames('inline-flex focus:outline-none', className)}
        {...props}><img
        src={siteSettings.logo.url}
        alt={siteSettings.logo.alt}
        height={siteSettings.logo.height}
        width={siteSettings.logo.width}
        layout="fixed"
        loading="eager"
      /></Link>
    )
}

export default Logo