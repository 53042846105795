
import OrderInformation from "../../components/order/order-information"
import Container from "../../components/ui/container"
import Divider from "../../components/ui/divider"

function OrderPage() {
    return (
        <>
            <Divider />
            <Container>
                <OrderInformation />
            </Container>
            <Divider />
        </>
    )
}

export default OrderPage