import { useCallback } from 'react';
import { IoCheckmarkCircle, IoAlertCircle } from 'react-icons/io5';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetOrderByIdQuery } from '../../services/slices/order';
import priceFormatter from '../../utils/price-formater';
import OrderDetails from './order-details';
import OrderStatus from './order-status';
import { Helmet } from "react-helmet";
export default function OrderInformation() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const createdStatus = searchParams.get('created')
  const { data: order, isSuccess, error, isLoading } = useGetOrderByIdQuery(id)

  const Information = ({ order }) => {
    if (order.estado.id > 5) {
      return (
        <div className="mt-8 border border-skin-base bg-skin-secondary px-4 lg:px-5 py-4 rounded-md flex items-center justify-start text-skin-base text-sm md:text-base mb-6 lg:mb-8">
          <span className="w-10 h-10 me-3 lg:me-4 rounded-full bg-skin-red bg-opacity-20 flex items-center justify-center flex-shrink-0">
            <IoAlertCircle className="w-5 h-5 text-skin-red" />
          </span>
          {order.estado.descripcion}
        </div>
      )
    }
    if (createdStatus) {
      return (<div className="mt-8 border border-skin-base bg-skin-secondary px-4 lg:px-5 py-4 rounded-md flex items-center justify-start text-skin-base text-sm md:text-base mb-6 lg:mb-8">
        <span className="w-10 h-10 me-3 lg:me-4 rounded-full bg-skin-primary bg-opacity-20 flex items-center justify-center flex-shrink-0">
          <IoCheckmarkCircle className="w-5 h-5 text-skin-primary" />
        </span>
        {order.estado.descripcion}
      </div>)
    } else {
      return (
        <OrderStatus status={order?.estado} />
      )
    }
  }

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className="xl:px-32 2xl:px-44 3xl:px-56">
      <Helmet>
        <title>{`Orden Nº ${order.id} - Lysto`}</title>
      </Helmet>
      <Information order={order} />
      <ul className="border border-skin-base bg-skin-secondary rounded-md flex flex-col md:flex-row mb-7 lg:mb-8 xl:mb-10">
        <li className="text-skin-base font-semibold text-base lg:text-lg border-b md:border-b-0 md:border-r border-dashed border-skin-two px-4 lg:px-6 xl:px-7 py-4 md:py-5 lg:py-6 last:border-0">
          <span className="uppercase text-xs block text-skin-muted font-normal leading-5">
            Numero de Pedido:
          </span>
          {order.id}
        </li>
        <li className="text-skin-base font-semibold text-base lg:text-lg border-b md:border-b-0 md:border-r border-dashed border-gray-300 px-4 lg:px-6 xl:px-7 py-4 md:py-5 lg:py-6 last:border-0">
          <span className="uppercase text-[11px] block text-skin-muted font-normal leading-5">
            Fecha:
          </span>
          {new Date(order.createdAt).toLocaleDateString('es-CL', { year: "numeric", month: "short", day: "numeric" })}
        </li>
        <li className="text-skin-base font-semibold text-base lg:text-lg border-b md:border-b-0 md:border-r border-dashed border-gray-300 px-4 lg:px-6 xl:px-7 py-4 md:py-5 lg:py-6 last:border-0">
          <span className="uppercase text-[11px] block text-skin-muted font-normal leading-5">
            Email:
          </span>
          {order.cliente.email}
        </li>
        <li className="text-skin-base font-semibold text-base lg:text-lg border-b md:border-b-0 md:border-r border-dashed border-gray-300 px-4 lg:px-6 xl:px-7 py-4 md:py-5 lg:py-6 last:border-0">
          <span className="uppercase text-[11px] block text-skin-muted font-normal leading-5">
            Total:
          </span>
          {priceFormatter.format(order.precioTotal)}
        </li>
        <li className="text-skin-base font-semibold text-base lg:text-lg border-b md:border-b-0 md:border-r border-dashed border-gray-300 px-4 lg:px-6 xl:px-7 py-4 md:py-5 lg:py-6 last:border-0">
          <span className="uppercase text-[11px] block text-skin-muted font-normal leading-5">
            Metodo de Pago:
          </span>
          {order.mediopago.creditCardType} {order.mediopago.last4CardDigits}
        </li>
      </ul>

      <OrderDetails />
      {/* <div><pre>{JSON.stringify(order, null, 2)}</pre></div>; */}
    </div>
  )
}
