import { lystoApi } from "../config"
export const clientApi = lystoApi.injectEndpoints({
    endpoints: (build) => ({
        getPaymentMethods: build.query({
            query: (id) => `cliente/${id}/mediospago`,
            providesTags: ['PaymentMethods'],
            transformResponse: (response, meta, arg) => {
                return response.filter(method => method.idEstado === 1)
            }
          }),
        deletePaymentMethods: build.mutation({
            query: ({uid, pid}) => ({
                url: `cliente/${uid}/mediospago/${pid}`,
                method: 'DELETE',
            }), 
            invalidatesTags: ['PaymentMethods']
        }),
    }),
    overrideExisting: false,
})

export const {useGetPaymentMethodsQuery, useDeletePaymentMethodsMutation} = clientApi