import { useSelector } from "react-redux"
import AlertError from "./error"
import AlertErrorList from "./errorList"

const Alert = ({children, active}) => {
    return (
        <div className="">
        {active && children}
        </div>
    )
}

const ManageAlert = () => {
    const ui = useSelector((state) => state.ui)
    return (
    <Alert active={ui.displayAlert}>
        {ui.alertView === 'ERROR_ALERT_VIEW_WITH_CONTENT'  && <AlertError content={ui.alertViewContent}/> }
        {ui.alertView === 'REQUIRE_ALERT_VIEW_WITH_CONTENT'  && <AlertErrorList content={ui.alertViewContent}/> }
     </Alert>
    )
}

export default ManageAlert