const MinusIcon = ({
    color = 'currentColor',
    width = '12',
    height = '2',
    opacity = '0.8',
  }) => {
    return (
      <svg
        className="transition-all"
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={opacity}>
          <path
            d="M3.15109 11.8438L10.174 11.8439L11.8264 11.8438L18.8493 11.8439C19.0772 11.8439 19.284 11.7515 19.4335 11.602C19.5831 11.4524 19.6755 11.2455 19.6754 11.0177C19.6755 10.5608 19.3062 10.1915 18.8493 10.1916L11.8264 10.1915L10.1741 10.1915L3.15109 10.1915C2.69427 10.1915 2.32496 10.5608 2.32496 11.0177C2.32486 11.4746 2.69416 11.8439 3.15109 11.8438Z"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
          />
        </g>
      </svg>
    );
  };
  
  export default MinusIcon;
  