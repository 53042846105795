import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../../store/slices/modal"
import priceFormatter from "../../utils/price-formater"
import useCart from "../../utils/use-cart"
import ProductControl from "../Cart/ProductControl"
import CartIcon from "../icons/cart-icon"
import Button from "../ui/button"
import ThumbnailCarousel from "../ui/carousel/thumbnail-carousel"
import CloseButton from "../ui/close-button"
import Heading from "../ui/heading"
import Text from "../ui/text"
import RelatedProductFeed from "./feeds/related-product-feed"
import cn from "classnames";

const breakpoints = {
    '1536': {
        slidesPerView: 6,
    },
    '1280': {
        slidesPerView: 5,
    },
    '1024': {
        slidesPerView: 4,
    },
    '640': {
        slidesPerView: 3,
    },
    '360': {
        slidesPerView: 2,
    },
    '0': {
        slidesPerView: 1,
    },
};

const ProductPopup = () => {
    const { data } = useSelector((state) => state.modal)
    const { isInStock, increment, decrement, getItemQuantity } = useCart()
    const outOfStock = !isInStock(data.id);

    const dispatch = useDispatch()

    const onDecrement = (e) => {
        e.stopPropagation()
        decrement({ item: data })
    }

    const onIncrement = (e) => {
        e.stopPropagation()
        increment({ item: data })
    }

    return (
        <div className="md:w-[600px] lg:w-[940px] xl:w-[1180px] 2xl:w-[1360px] mx-auto p-1 lg:p-0 xl:p-3 bg-skin-fill rounded-md">
            <CloseButton onClick={() => dispatch(closeModal())} />
            <div className="overflow-hidden">
                <div className={cn("px-4 md:px-6 lg:p-8 2xl:p-10 mb-9 lg:mb-2 pt-4 md:pt-7 2xl:pt-10 ",{ grayscale: !data.stock })}>
                    <div className="lg:flex items-start justify-between">
                        <div className="xl:flex items-center justify-center overflow-hidden mb-6 md:mb-8 lg:mb-0">
                            {!!data?.gallery?.length ? (
                                <ThumbnailCarousel gallery={data?.gallery} />
                            ) : (
                                <div className="w-auto flex items-center justify-center">
                                    <img
                                        src={data?.image?.original}
                                        alt={data?.name}
                                        width={650}
                                        height={590}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="flex-shrink-0 flex flex-col lg:ps-5 xl:ps-8 2xl:ps-10 lg:w-[430px] xl:w-[470px] 2xl:w-[480px]">
                            <div className="pb-5">
                                <div
                                    className="mb-2 md:mb-2.5 block -mt-1.5"
                                    onClick={() => { }}
                                    role="button"
                                > <span className="text-11 sm:text-xs">{data.nombreMarca}</span>
                                    <h2 className="text-skin-base text-lg md:text-xl xl:text-2xl font-medium transition-colors duration-300 hover:text-skin-primary">
                                        {data.name}
                                    </h2>
                                </div>
                                <div className="flex items-center mt-5">
                                    <div className="text-skin-base font-bold text-base md:text-xl xl:text-[22px]">
                                        {priceFormatter.format(data.effectivePrice)}
                                    </div>
                                    {Boolean(data.discountPercentage) && (
                                        <>
                                            <del className="text-sm md:text-15px ps-3 text-skin-base text-opacity-50">
                                                {priceFormatter.format(data.precioBase)}
                                            </del>
                                            <span className="inline-block rounded font-bold text-xs md:text-sm bg-skin-tree bg-opacity-20 text-skin-tree uppercase px-2 py-1 ms-2.5">
                                                - {data.discountPercentage}%
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="pt-1.5 lg:pt-3 xl:pt-4 space-y-2.5 md:space-y-3.5">
                                <ProductControl
                                    quantity={getItemQuantity(data.id)}
                                    variant="single"
                                    increase={onIncrement}
                                    decrease={onDecrement} disabled={!data.stock && outOfStock} />

                            </div>

                            <div className="pt-6 xl:pt-8">
                                <Heading className="mb-3 lg:mb-3.5">
                                    Descripción:
                                </Heading>
                                <Text variant="small">
                                    {data.descripcion.split(' ').slice(0, 40).join(' ')}
                                </Text>
                            </div>

                        </div>

                    </div>
                </div>
                <RelatedProductFeed
                    idRelated={data.id}
                    carouselBreakpoint={breakpoints}
                    className="mb-0.5 md:mb-2 lg:mb-3.5 xl:mb-4 2xl:mb-6"
                />
            </div>
        </div>
    )
}
export default ProductPopup