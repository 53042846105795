import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    active: false,
    outgoing: '',
}

const callbackSlice = createSlice({
    name: 'callback',
    initialState,
    reducers: {
        toggleActiveCallback: (state, action) => {
            const { outgoing } = action.payload
            state.active = !state.active
            state.outgoing =  outgoing
        },
    }
})

export const { toggleActiveCallback } = callbackSlice.actions

export default callbackSlice.reducer