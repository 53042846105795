import { Fragment } from "react"
import { useGetPaymentMethodsQuery } from "../../services/slices/paymentsMethods"
import { useAuth } from "../../store/useAuth"
import PaymentBox from "../payment/payment-content"

function PaymentsCheckout() {
    const auth = useAuth()
    const { data, error, isLoading } = useGetPaymentMethodsQuery(auth.user?.id)
    return (
        <Fragment>
            {isLoading ? <h3>Cargando</h3> : <PaymentBox data={data} default_payment={auth.user?.id_mediopago_default}/>}
        </Fragment>

    )
}

export default PaymentsCheckout