import cn from "classnames"
import { IoClose } from "react-icons/io5"

const CloseButton  = ({ className, onClick }) => {
    return (
        <button
        onClick={onClick}
        aria-label="Close Button"
        className={cn(
          'fixed z-10 inline-flex items-center justify-center w-8 h-8 lg:w-9 lg:h-9 transition duration-200 text-skin-base text-opacity-50 focus:outline-none  hover:text-opacity-100 top-0.5 md:top-2 lg:top-7 xl:top-10 right-0.5 md:right-2 lg:right-7 xl:right-10 bg-skin-fill lg:bg-transparent rounded-full',
          className
        )}
      >
        <IoClose className="text-xl lg:text-2xl" />
      </button>
    )
}

export default CloseButton