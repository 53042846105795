import cn from "classnames";
import { useEffect } from "react";
import { useGetStoreHomeQuery } from "../../services/slices/misc";
import { useGetCategoriesQuery } from "../../services/slices/product";
import structureCategories from "../../utils/structure-category";
import Alert from "../ui/alert";
import CategoryListCardLoader from "../ui/loaders/category-list-card-loader";
import Scrollbar from "../ui/scrollbar";
import SidebarMenu from "../ui/sidebar-menu";

const CategoryDropdownSidebar = ({
    className,
}) => {
    const {
        data,
        isLoading,
        error,
    } = useGetStoreHomeQuery({},{refetchOnMountOrArgChange: true})

    return (

        <aside
            className={cn('lg:sticky lg:top-5 category-mobile-sidebar', className)}
        >
            <div class="flex items-center py-2">
                <div class="flex-grow h-px bg-gray-400"></div>
                <span class="flex-shrink text-sm text-gray-500 px-3 font-light">CATEGORIAS</span>
                <div class="flex-grow h-px bg-gray-400"></div>
            </div>

            <div className="max-h-full overflow-hidden rounded border border-skin-base">
                {error ? (
                    <div className="2xl:pe-10">
                        <Alert message={error.message} />
                    </div>
                ) : (
                    <Scrollbar className="category-scrollbar w-full h-full">
                        {isLoading && !data?.categories?.length ? (
                            Array.from({ length: 15 }).map((_, idx) => (
                                <CategoryListCardLoader
                                    key={`category-list-${idx}`}
                                    uniqueKey="category-list-card-loader"
                                />
                            ))
                        ) : (
                            <SidebarMenu items={data.categories} />
                        )}
                    </Scrollbar>
                )}
            </div>
        </aside>
    );
};

export default CategoryDropdownSidebar;
