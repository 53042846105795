import SearchIcon from "../icons/search-icon"
import cn from 'classnames';
const SearchBox = ({
    className,
    searchId = 'search',
    variant = 'border',
    value,
    onSubmit,
    onClear,
    onFocus,
    ...rest
  }) => {
    return (
        <form
        className="flex w-full relative rounded-md"
        noValidate
        role="search"
        onSubmit={onSubmit}
      >
        <label htmlFor={searchId} className="flex flex-1 items-center py-0.5">
          <input
            id={searchId}
            className={cn(
                'text-heading outline-none w-full h-[52px] ps-5 md:ps-6 pe-14 md:pe-16 bg-skin-full text-skin-base text-sm lg:text-15px rounded-md transition-all duration-200 focus:border-skin-primary focus:ring-1 focus:ring-skin-form',
                {
                  'border border-skin-base': variant === 'border',
                  'bg-skin-one': variant === 'fill',
                }
              )}
            placeholder="¿Qué estas buscando?"
            aria-label={searchId}
            autoComplete="off"
            value={value}
            onFocus={onFocus}
            {...rest}
          />
          <span className="w-14 md:w-16 h-full absolute top-0 right-0 flex flex-shrink-0 justify-center items-center focus:outline-none">
            <SearchIcon className="w-5 h-5 text-skin-base text-opacity-40" />
          </span>
        </label>

      </form>
    )
}

export default SearchBox