import { Fragment } from "react";
import useWindowSize from "../../utils/use-window-size";
import CartIcon from "../icons/cart-icon";
import PlusIcon from "../icons/plus-icon";
import Button from "../ui/button";
import Counter from "../ui/counter";

function ProductControl({ quantity, increase, decrease, variant = 'default' , disabled}) {
    const { width } = useWindowSize();
    const iconSize = width > 480 ? '19' : '17'
    const AddButton = ({disabled}) => {
        if (variant === 'single') {
            return (
                <Button
                    onClick={increase}
                    className="w-full px-1.5"
                    disabled={disabled}
                >
                    <CartIcon color="#ffffff" className="me-3" />
                    Añadir al carrito
                </Button>)
        }
        return (
            <button
                className="inline-flex bg-skin-primary rounded-full w-8 lg:w-10 h-8 lg:h-10 text-skin-inverted text-4xl items-center justify-center focus:outline-none focus-visible:outline-none"
                aria-label="Count Button"
                data-testid="add"
                disabled={disabled}
                onClick={increase}
            >
                <PlusIcon width={iconSize} height={iconSize} opacity="1" />
            </button>
        )
    }
    return (
        <Fragment>
            {quantity === 0 ? 
                < AddButton disabled={disabled} />  
             :
                <Counter variant={variant} quantity={quantity} onDecrease={decrease} onIncrease={increase} disabled={disabled}/>
            }
        </Fragment>
    )
}

export default ProductControl