import { createSelector } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, decreaseItemQty, increaseItemQty, removeProduct } from "../store/slices/cart";

const getItem = (items, id) => {
    return items.find((item) => item.id === id);
}

const inStock = (items, id) => {
    const item = getItem(items, id)
    if (item) return item?.cantidad < item.stock;
    return false;
}

const matchQuantity = (cart, id) => {
    let quantity = 0
    if(id in cart.productsQty){
        quantity = cart.productsQty[id]
    }
    return quantity
}
const selectCart = state => state.cart
const selectProductsCartWithQty = createSelector([selectCart], (cart) => {
    return cart.cartProducts.map(item => ({...item, cantidad: cart.productsQty[item.id] }))
})

const selectIsEmpty = createSelector([selectCart], (cart) => {
    if (cart.cartLength === 0 ){
        return true
    } 
    return false
})
const useCart = () => {
    const cart = useSelector((state) => state.cart)
    const dispatch = useDispatch()

    const resetCart = () => dispatch(clearCart());
    const increment = (item) => dispatch(increaseItemQty(item));
    const decrement = (item) => dispatch(decreaseItemQty(item));
    const removeItem = (item) => dispatch(removeProduct(item))
    const items = useSelector(selectProductsCartWithQty)
    const isInStock = useCallback( (id) => inStock(items, id) , [items])
    

   
    const isEmpty = useSelector(selectIsEmpty)
    
    const calculateTotalPriceItem = (item) => { 
        const total = item.effectivePrice * item.cantidad
        return total
    }

    const calculateProductsCartTotal = (items) => {
        const total = items.reduce((partialSum, item) => partialSum + item.effectivePrice*item.cantidad, 0)
        return total
    }

    const getItemQuantity = useCallback((id) => matchQuantity(cart, id), [cart])

    return { items,
        resetCart, 
        increment, 
        decrement, 
        isInStock, 
        removeItem, 
        calculateTotalPriceItem, 
        calculateProductsCartTotal,
        getItemQuantity,
        isEmpty }
}

export const productsCartExportFormat = (cart) => {
    var products = []
    Object.keys(cart.productsQty).map((idProducto, index) => {
        products.push({idProducto, cantidad: cart.productsQty[idProducto]})
      });
    return products
}

export default useCart;