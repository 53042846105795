import React from "react";
import { Element } from 'react-scroll';

import { useGetMiscHomeQuery } from "../../services/slices/misc";
import Container from "../../components/ui/container";
import CategoryDropdownSidebar from "../../components/category/category-dropdown-sidebar";
import { useGetBannersQuery } from "../../services/slices/banners";
import BannerAllCarousel from "../../components/common/banner-all-carousel";
import ProductsCarousel from "../../components/Products/products-carousel";
import DownloadApps from "../../components/common/download-apps";
import {Helmet} from "react-helmet";

function Home(props) {
    const { data, isLoading, error } = useGetMiscHomeQuery({},{
        refetchOnMountOrArgChange: true
      })
    const { data: dataBanners, isLoading: isLoadingBanners } = useGetBannersQuery({},{
        refetchOnMountOrArgChange: true
      })
    return (
        <Container>
            <Helmet>
                <title>Supermercado Online - Lysto</title>
                <meta name="description" content="Supermercado online con envío gratis, precios rebajados, tus marcas de siempre y las emergentes también." />
            </Helmet>
            {isLoading || isLoadingBanners ? <h3>Cargando...</h3> :
                <>
                    <BannerAllCarousel
                        data={dataBanners}
                        buttonSize="small"
                        className="mb-5 lg:mb-6 xl:mb-7 mt-3 lg:mt-4 xl:mt-5"
                    />

                    <Element name="grid" className="flex mb-5 py-1">
                        <CategoryDropdownSidebar className="flex-shrink-0 pe-4 xl:pe-4 hidden lg:block w-80 xl:w-[320px]" />
                        <div className="minimal-main-content w-full xl:-ms-8">
                            <ProductsCarousel
                                sectionHeading="Ofertas"
                                categorySlug="offers"
                                products={data?.offers}
                                loading={isLoading}
                                error={error?.message}
                                limit={5}
                                uniqueKey="offers"
                            />
                            <ProductsCarousel
                                sectionHeading="Nuevos Productos"
                                categorySlug="newproducts"
                                products={data?.newProducts}
                                loading={isLoading}
                                error={error?.message}
                                limit={5}
                                uniqueKey="new-products"
                            />


                        </div>
                    </Element>

                </>
            }

            <DownloadApps />
        </Container>
    );
}

export default Home;