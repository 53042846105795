import { lystoApi } from "../config";

export const brandApi = lystoApi.injectEndpoints({
    endpoints: (build) => ({
        getBrand: build.query({
            query: (bid) => ({
                url: `/marca/${bid}`,
                method: 'GET',
                params: {'filter[include]': 'ProductoTipoConsumible'}
            }),
        })
    })
})
export const { useGetBrandQuery } = brandApi