import cn from "classnames"
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSignUpMutation } from "../../services/slices/client";
import Button from "../ui/button";
import Input from "../ui/form/input";
import PasswordInput from "../ui/form/password-input";
import Logo from "../ui/logo"
import { setCredentials } from "../../store/slices/auth";
import { closeModal, openModal } from "../../store/slices/modal";

const SignUpForm = ({
    isPopup = true,
    className,
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [signUp, { data, isLoading, isSuccess }] = useSignUpMutation()


    const {
        register,
        handleSubmit,
        formState: { errors }, watch
    } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    function onSubmit({ name, lastname, email, password, telefono }) {
        const _data = {
            username: `${name} ${lastname}`,
            nombre: name,
            apellido: lastname,
            telefono,
            password,
            email
        }
        signUp(_data)
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(setCredentials({user: data, id: data?.accessToken?.id}))
            dispatch(closeModal())
            navigate('/')
        }
    }, [isSuccess])

    const handleLogin = () => {
        dispatch(openModal({view: 'LOGIN_VIEW'}))
    }

    return (
        <div
            className={cn(
                'flex bg-skin-fill mx-auto rounded-lg w-full lg:w-[1000px] 2xl:w-[1200px]',
                className
            )}
        >
            <div className="flex bg-skin-fill mx-auto rounded-lg overflow-hidden w-full">
                <div className="md:w-[55%] xl:w-[60%] registration hidden md:block">
                    <img
                        src="/assets/images/registration.png"
                        alt="sign up"
                        width={800}
                        height={620}
                        className="w-full"
                    />
                </div>

                <div className="w-full md:w-[45%] xl:w-[40%] py-6 sm:py-10 px-4 sm:px-8 lg:px-12  rounded-md shadow-dropDown flex flex-col justify-center">
                    <div className="text-center mb-6 pt-2.5">
                        <div>
                            <Logo />
                        </div>
                        <h4 className="text-skin-base font-semibold text-xl sm:text-2xl  sm:pt-3 ">
                            ¡Regístrate!
                        </h4>
                        <div className="text-sm sm:text-base text-body text-center mt-3 mb-1">
                            ¿Ya está registrado?
                            <button
                                type="button"
                                className="ms-1 text-sm sm:text-base text-skin-primary font-semibold hover:no-underline focus:outline-none"
                                onClick={handleLogin}
                            >
                                Iniciar sesión ahora
                            </button>
                        </div>
                    </div>



                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col justify-center"
                        noValidate
                    >
                        <div className="flex flex-col space-y-4">
                            <Input
                                label="Nombre"
                                type="text"
                                variant="solid"
                                {...register('name', {
                                    required: 'Se requiere nombre',
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                label="Apellido"
                                type="text"
                                variant="solid"
                                {...register('lastname', {
                                    required: 'Se requiere apellido',
                                })}
                                error={errors.lastname?.message}
                            />
                            <Input
                                label="Email"
                                type="email"
                                variant="solid"
                                {...register('email', {
                                    required: 'Debe proporcionar su dirección de correo electrónico',
                                    pattern: {
                                        value:
                                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Proporcione una dirección de correo electrónico válida",
                                    },
                                })}
                                error={errors.email?.message}
                            />
                            <Input
                                label="Telefono"
                                type="text"
                                variant="solid"
                                {...register('telefono', {
                                    required: 'Se requiere telefono',
                                })}
                                error={errors.telefono?.message}
                            />
                            <PasswordInput
                                label="Contraseña"
                                name="password"
                                error={errors.password?.message}
                                {...register('password', {
                                    required: "Debe proporcionar una contraseña",
                                })}
                            />
                            <PasswordInput
                                name="password_repeat"
                                label="Confirmar contraseña"
                                {...register('confirmPassword', {
                                    validate: value =>
                                        value === password.current || "Las contraseñas no coinciden"
                                })}
                                error={errors.confirmPassword?.message}
                            />

                            <div className="relative">
                                <Button
                                    type="submit"
                                    loading={false}
                                    disabled={false}
                                    className="h-11 md:h-12 w-full mt-2 font-15px md:font-15px tracking-normal"
                                    variant="formButton"
                                >
                                    Registrarme
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default SignUpForm