import { RadioGroup } from '@headlessui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { TiTimes } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { useDeletePaymentMethodsMutation, useGetPaymentMethodsQuery } from '../../services/slices/paymentsMethods';
import { siteSettings } from '../../settings/site-settings';
import { toggleActiveCallback } from '../../store/slices/callback';
import { selectPayment } from '../../store/slices/checkout';
import { useAuth } from '../../store/useAuth';

function PaymentBox({ data, default_payment }) {
  const dispatch = useDispatch()
  const auth = useAuth()
  const checkout = useSelector((state) => state.checkout)
  const indexByPaymentMethodId = data.findIndex(function (paymentMethod) { return paymentMethod.id === checkout.paymentMethod?.id })
  const [deletePaymentMethods] = useDeletePaymentMethodsMutation()
  const { refetch } = useGetPaymentMethodsQuery(auth.user?.id)

  const handleOneclick = () => {
    dispatch(toggleActiveCallback({outgoing: window.location.pathname}))
    window.location.replace(`${siteSettings.payments.oneclick}?TOKEN=${auth.token}`);
  }

  const handleDelete = (card) => {
    deletePaymentMethods({ uid: auth.user.id, pid: card.id })
    refetch()
  }
  return (
    <div className="text-15px h-full flex flex-col justify-between -mt-4 md:mt-0">

      <RadioGroup value={data[indexByPaymentMethodId]} onChange={(paymentMethod) => dispatch(selectPayment({ paymentMethod }))} className="md:grid md:grid-cols-2 md:gap-5 auto-rows-auto space-y-4 md:space-y-0">
        <RadioGroup.Label className="sr-only">Default</RadioGroup.Label>
        {data?.map((card, index) => (
          <RadioGroup.Option
            key={index}
            value={card}
            className={({ active, checked }) =>
              `${checked ? 'border-skin-primary' : 'border-skin-base'}
          border-2 relative shadow-md focus:outline-none rounded-md p-5 block cursor-pointer min-h-[112px] h-full group address__box`

            }
            style={{
            }}
          >

            <RadioGroup.Label
              as="h3"
              className="text-skin-base font-semibold mb-2 -mt-1"
            >
              {card?.creditCardType}
            </RadioGroup.Label>
            <RadioGroup.Description
              as="div"
              className="text-skin-muted leading-6"
            >
              {card?.numero}
            </RadioGroup.Description>
            <div className="flex absolute right-3 top-3 z-10 lg:opacity-0 transition-all address__actions">
              <button
                onClick={() => handleDelete(card)}
                className="flex justify-center items-center bg-skin-red h-6 w-6 rounded-full text-skin-inverted text-opacity-80 text-base"
              >
                <span className="sr-only">Eliminar</span>
                <TiTimes />
              </button>
            </div>
          </RadioGroup.Option>
        ))}
        <button
          className="w-full border-2 transition-all border-skin-base rounded font-semibold p-5 px-10 cursor-pointer text-skin-primary flex justify-start hover:border-skin-primary items-center min-h-[112px] h-full"
          onClick={handleOneclick}
        >
          <AiOutlinePlus size={18} className="me-2" />
          Agregar
        </button>
      </RadioGroup>

    </div>
  )
}

export default PaymentBox