import ProgressBox from "./progress-box";

const OrderStatus = ({ status }) => {
    const data = [
        {
            "id": 1,
            "name": "Recibido",
            "color": "#02B290",

        },
        {
            "id": 2,
            "name": "En Proceso",
            "color": "#02B290",
        
        },
        {
            "id": 3,
            "name": "En Camino",
          
            "color": "#FED030",

        },
        {
            "id": 4,
            "name": "Entregado",
            "color": "#02B290",

        }
    ]
    return (
        <ProgressBox data={data} status={status} />
    ) 
};

export default OrderStatus;
