import Heading from "../../../components/ui/heading";
import AccountLayout from "../../../components/User/AccountLayout";
import PaymentBox from "../../../components/payment/payment-content";
import { useGetPaymentMethodsQuery } from "../../../services/slices/paymentsMethods";
import { useAuth } from "../../../store/useAuth";
import { useCallback, useEffect } from "react";
import { toggleActiveCallback } from "../../../store/slices/callback";
import { redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function PaymentPage() {
    const auth = useAuth()
    const { data, isLoading, refetch } = useGetPaymentMethodsQuery(auth.user?.id, {
        refetchOnMountOrArgChange: true
    })
    useCallback(() => {
        refetch()
    }, [])

    const manageCallback = useSelector((state) => state.callback)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        console.log(manageCallback)
        if (manageCallback.active) {
            navigate(manageCallback.outgoing)
            dispatch(toggleActiveCallback({}))
        }
    }, [])
    return (
        <AccountLayout>
            <Heading variant="titleLarge" className="mb-5 md:mb-6 lg:mb-7 lg:-mt-1">
                Mis tarjetas
            </Heading>
            {isLoading ? <h3>Cargando</h3> : <PaymentBox data={data} />}
        </AccountLayout>
    )
}

export default PaymentPage