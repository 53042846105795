import Container from "../../components/ui/container";
import PageHeroSection from "../../components/ui/page-hero-section";
import { useGetTosQuery } from "../../services/slices/misc";
import {Helmet} from "react-helmet";

const TOSPage = () => {
    const {
        data,
    } = useGetTosQuery({},{refetchOnMountOrArgChange: true})
    return (
        <>
        <Helmet>
                <title>Términos y Condiciones - Lysto</title>
            </Helmet>
        <PageHeroSection heroTitle="Términos y Condiciones" />
        <div className="py-12 lg:py-16 2xl:py-20">

        <Container>
            {data && <div className="w-full xl:max-w-[1200px] mx-auto" dangerouslySetInnerHTML={{ __html: data.tos }} />}
        </Container>
        </div>
     
        </>
    )
}

export default TOSPage