import { createListenerMiddleware } from "@reduxjs/toolkit";
import { logEvent } from "firebase/analytics";
import { analytics } from "../index";
import { createCheckoutLogAnalytics } from "../logger/analytics";
import { setCredentials } from "./slices/auth";

const analyticsListenerMiddleware = createListenerMiddleware()
analyticsListenerMiddleware.startListening({
    actionCreator: setCredentials,
    effect: async (action, listenerApi) => {
        console.log(action)
        await logEvent(analytics, 'login', {
            method: 'login'
        })
    }

})

/* analyticsListenerMiddleware.startListening({
    type: 'api/executeQuery/fulfilled',
    effect: async (action, listenerApi) => {
        console.log(action)
    }
}) */

analyticsListenerMiddleware.startListening({
    type: 'api/executeMutation/fulfilled',
    effect: async (action, listenerApi) => {
        const { meta: { arg }, payload } = action
        createCheckoutLogAnalytics(arg, payload)
    }

})

export default analyticsListenerMiddleware