import Container from "../../components/ui/container"
import Button from "../../components/ui/button"
import priceFormatter from "../../utils/price-formater"

const cajas = [
    {
        id: 'economica', title: 'Económica',
        imageUrl: '/assets/images/single/cajas/nuevas/economica.png', content: [
            '1 Salsa de Tomate El Vergel 200gr.',
            '1 Spaghetti Di Paolo 400gr',
            '1 Aceite Vegetal Campo Lindo 900ml',
            '1 Campo Lindo Arroz Grado 2 Grano Largo 1Kg.',
            '1 Té Supremo Premium 20 Bolsitas',
            '1 Campo Lindo Azúcar 1kg.',
            '1 Sal Lobos Fina 1Kg.',
            '1 Atún Lomitos en Agua 160g.',
            '1 Crema Nestlé 157g.',
            '1 Pan de pascua Castaño clásico 500gr',
            '1 Néctar Watts 1lt Naranja',
            '1 Duraznos Mitades Aconcagua 560gr.',
            '3 Chocman Bizcocho 33gr.'
        ], price: 14990
    },
    {
        id: 'familiar', title: 'Familiar',
        imageUrl: '/assets/images/single/cajas/nuevas/familiar.png',
        content: [
            '1 Salsa de Tomate El Vergel 200gr.',
            '1 Spaghetti Di Paolo 400gr',
            '1 Aceite Vegetal Campo Lindo 900ml',
            '1 Campo Lindo Arroz Grado 2 Grano Largo 1Kg.',
            '1 Duraznos Mitades Aconcagua 560gr.',
            '1 Té Supremo Premium 20 Bolsitas',
            '1 Campo Lindo Azúcar 1kg.',
            '1 Sal Lobos Fina 1Kg.',
            '1 Crema Nestlé 157g.',
            '1 Mermelada Tia Lia Damasco 250gr',
            '1 Crackelet Clásica 85gr.',
            '1 Pan de pascua Castaño clásico 500gr',
            '1 Atún Lomitos en Agua 160g.',
            '1 Harina Mont Blanc Sin Polvos de Hornear 1kg',
            '1 Lays Stax Original 134g',
            '2 Chocman Bizcocho 33gr.',
            '1 Espirales Di Paolo 400gr',
            '1 Vino Santa Carolina 3 Estrellas Cabernet Sauvignon 750 ml',
            '2 Jugo en Polvo Livean',
            '1 Maní 160gr Evercrisp'],
            price: 21890
    },
    {
        id: 'premium', title: 'Premium',
        imageUrl: '/assets/images/single/cajas/nuevas/premium.png',
        content: [
            "2 Salsa Pomarola DoypPack Carozzi 200gr.",
            "1 Carozzi Spaghetti 5 400 GR.",
            "1 Aceite Vegetal Campo Lindo 900ml",
            "1 Arroz Miraflores Grado 1 1kg",
            "1 Duraznos Mitades Aconcagua 560gr.",
            "1 Té Supremo Premium 20 Bolsitas",
            "1 Azúcar Iansa 1kg",
            "1 Sal Lobos Fina 1Kg.",
            "1 Crema de Leche Colun 200cc",
            "1 Mermelada Tia Lia Damasco 250gr",
            "1 Crackelet Clásica 85gr.",
            "1 Pan De Pascua Castaño 500gr.",
            "1 Atún Lomitos en Agua 160g.",
            "1 Harina Mont Blanc Sin Polvos de Hornear 1kg",
            "1 Lays Stax Original 134g",
            "2 Chocman Bizcocho 33gr.",
            "1 Mayonesa Don Juan 100gr",
            "1 Ketchup Hellman's 100 gr.",
            "1 Carozzi Espirales 400gr.",
            "1 Espumante Santa Carolina Demi-Sec 750cc.",
            "1 Vino Santa Carolina 3 Estrellas Cabernet Sauvignon 750 ml",
            "1 Aji Traverso 100gr.",
            "1 Cafe Gold Tradicional 50 gr.",
            "1 Galletas Navidad Costa",
            "1 Ambrosoli Gelatina Frambuesa 100 rg.",
            "1 Jugo en Polvo Livean",
            "1 Maní Salado 160 gr. Evercrisp",
        ], price: 30490
    },
    {
        id: 'gourmet', title: 'Gourmet',
        imageUrl: '/assets/images/single/cajas/nuevas/gourmet.png',
        content: [
            "1 Papas Fritas Regionales Gololo 210g.",
            "1 Galletas  100% Natural  CRACKERS SAL DE MAR",
            "1 Pan De Pascua Castaño 500gr.",
            "2 Alfajor Guayacan",
            "1 Hummus Suk 380g.",
            "1 Tapenade de aceitunas 230gr",
            "1 Espumante Santa Carolina Demi-Sec 750cc",
            "1 Maní Tostado Natural 500gr Mercado Silvestre",
            "1 Té negro ceylán del valle de lumbini",
            "1 Caluga de Manjar Coco",
        ],price: 29690
    },
    {
        id: 'gourmetxl', title: 'Gourmet XL',
        imageUrl: '/assets/images/single/cajas/nuevas/gourmetxl.png', content: [
            "1 Papas Fritas Regionales Gololo 210g.",
            "1 Galletas  100% Natural  CRACKERS SAL DE MAR",
            "1 Pan De Pascua Castaño 500gr.",
            "2 Alfajor Guayacan",
            "1 Hummus Suk 380g.",
            "1 Tapenade de aceitunas 230gr",
            "1 Vino Santa Carolina Reserva Cabernet Sauvignon 750 ml",
            "1 Espumante Santa Carolina Demi-Sec 750cc",
            "1 Maní Tostado Natural 500gr Mercado Silvestre",
            "1 Té negro ceylán del valle de lumbini",
            "1 Caluga de Manjar Coco",
            "2 AWA Solar Con Gas 355ML",
            "1 Café Molido Blend Huila, Colombia - 250g",
            "1 Prosciutto Affettato 100G",
            "1 Popped Chips de papa con romero 105g.",
        ], price: 44990
    },

]



const CajasPage = () => {
    const jumpToReleventDiv = (id) => {
        console.log(id)
        const releventDiv = document.getElementById(id);
        // behavior: "smooth" parameter for smooth movement
        releventDiv.scrollIntoView({ behavior: "smooth" });
    }
    const openForm = () => {
        window.open('https://forms.gle/Fsj61uwXbCkZS9gv9', "_blank")
    }
    return (
        <>
            <div
                className="flex justify-center xl:min-h-[300px] py-16 md:py-20 w-full bg-cover bg-no-repeat bg-center"
                style={{
                    backgroundImage: `url(/assets/images/single/cajas/banner-cajas-canastalista.png)`,
                }}
            >
            </div>

            <div className="pt-6 lg:pt-7" style={{ background: '#171c31' }}>
                <Container>
                    <div style={{ margin: 'auto', }}>
                        <div className="flex flex-wrap" style={{ justifyContent: 'center', columnGap: '1em' }}>
                            {cajas.map(caja => {
                                return (<Button className="h-10 md:h-11 max-w-xs mt-2 font-15px md:font-15px tracking-normal" onClick={() => jumpToReleventDiv(caja.id)}>{caja.title}</Button>)
                            })}
                        </div>
                        <div id="tipos-cajas" className="pb-20" style={{ color: 'white', textTransform: 'uppercase' }}>
                            {
                                cajas.map(caja => {
                                    return (
                                        <div id={caja.id} style={{ width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                                <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', paddingTop: '3.5em', paddingBottom: '2em' }}>{caja.title}</h2>
                                            </div>
                                            <div className="flex flex-col sm:flex-row" style={{ width: '100%' }}>

                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={caja.imageUrl}
                                                        alt={`Productos Caja ${caja.id}`}

                                                        className="w-full"
                                                    />
                                                </div>

                                                <div style={{ width: '100%' }}>
                                                    <ul style={{ fontSize: '0.8em' }}>
                                                        {caja.content.map(item => {
                                                            return (<li>{item}</li>)
                                                        })}
                                                    </ul>
                                                    <div><span className="text-2xl font-bold">{priceFormatter.format(caja.price)}</span> +IVA</div>
                                                </div>
                                            </div>
                                        </div>)
                                })
                            }

                        </div>

                        <div style={{ fontSize: 20, margin: 'auto', width: '100%' }} className="flex justify-center sticky bottom-0">
                            <button onClick={openForm} className="px-3 py-1 mb-5 text-base justify-center font-body font-semibold rounded placeholder-white bg-skin-primary text-skin-inverted">Formulario de Contacto</button>
                        </div>

                    </div>

                </Container>
            </div>
        </>
    )
}
export default CajasPage