import { lystoApi } from "../config"

export const cartApi = lystoApi.injectEndpoints({
    endpoints: (build) => ({
        getCartPreview: build.query({
            query: (uid) => ({
                url: `cliente/${uid}/cart/preview`
            }),
            providesTags: ['Cart','Order']
        }),
        setCoupon: build.mutation({
            query: ({uid, codigoDescuento}) => ({
                url: `cliente/${uid}/cart/preview`,
                method: 'POST',
                body: {codigoDescuento}
            }),
            invalidatesTags: ['Order','Cart']
        })
    })
})

export const { useGetCartPreviewQuery, useSetCouponMutation } = cartApi