import Heading from "../ui/heading"
import Text from "../ui/text"

const EmptyCart = () => {
    return (
        <div className="px-5 md:px-7 pt-8 pb-5 flex justify-center flex-col items-center">
            <div className="flex mx-auto w-[220px] md:w-auto">
                <img
                    src="/assets/images/empty-cart.png"
                    alt={"Tu carro está vacío"}
                    width={270}
                    height={240}
                />
            </div>
            <Heading variant="titleMedium" className="mb-1.5 pt-8">
                Tu carro está vacío
            </Heading>
            <Text></Text>
        </div>
    )
}

export default EmptyCart