import { useParams } from "react-router-dom"
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs"
import CategoryHeroSection from "../../components/category/category-hero-section"
import ProductBundleGrid from "../../components/Products/ProductBundleGrid"
import Container from "../../components/ui/container"
import Divider from "../../components/ui/divider"
import { useGetBrandQuery } from "../../services/slices/brands"

function BrandPage() {
    const { id } = useParams()
    const { data, isLoading, error, isSuccess } = useGetBrandQuery(id,{refetchOnMountOrArgChange: true})

    return(
        <Container>
            {Boolean(isSuccess) && 
            <>
            <Breadcrumbs items={[{name: `Marca ${data?.name}`, url: `/brand/${id}` },]} />
            <CategoryHeroSection heroTitle={data?.name}/>
            </> }
            <Divider/>

            <ProductBundleGrid
            className="pt-3" data={data?.ProductoTipoConsumible} error={error} isLoading={isLoading}/>

        </Container>
    )
}

export default BrandPage