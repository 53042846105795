import { useDispatch } from "react-redux"
import { closeSidebar } from "../../../store/slices/ui"
import Logo from "../../ui/logo"
import { IoClose } from "react-icons/io5"
import Scrollbar from "../../ui/scrollbar"
import cn from "classnames"
import { Link } from "react-router-dom"
import { useGetStoreHomeQuery } from "../../../services/slices/misc"
import { useState } from "react"
import { IoIosArrowDown } from 'react-icons/io';

export default function MobileMenu() {
    const dispatch = useDispatch()
    const [activeMenus, setActiveMenus] = useState([]);
    const {
        data
    } = useGetStoreHomeQuery({},{refetchOnMountOrArgChange: true})

    const handleArrowClick = (menuName) => {
        let newActiveMenus = [...activeMenus];
        if (newActiveMenus.includes(menuName)) {
          var index = newActiveMenus.indexOf(menuName);
          if (index > -1) {
            newActiveMenus.splice(index, 1);
          }
        } else {
          newActiveMenus.push(menuName);
        }
        setActiveMenus(newActiveMenus);
      };


    const SubMenu = ({ dept, data, toggle, menuIndex }) => {
        if (!toggle) {
          return null;
        }
    
        dept = dept + 1;
    
        return (
          <ul className={cn('mobile-sub-menu', dept > 2 && '-ms-4')}>
            {data?.map((menu, index) => {
              const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;
    
              return (
                <ListMenu
                  dept={dept}
                  item={menu}
                  hasSubMenu={menu.subcategories}
                  menuName={menuName}
                  key={menuName}
                  menuIndex={index}
                  className={cn(dept > 1 && 'ps-4', dept > 2 && 'ps-8')}
                />
              );
            })}
          </ul>
        );
      };

    const ListMenu = ({
        dept,
        item,
        hasSubMenu,
        menuName,
        menuIndex,
        className = ''
    }) => item.name && (

        <li className={`transition-colors duration-200 ${className}`}>
            <div className="flex items-center justify-between relative">
                <Link
                    to={`/category/${item.id}`}
                    className="w-full menu-item relative py-4 ps-5 md:ps-7 pe-4 text-skin-base transition duration-300 ease-in-out"
                >
                    <span className="block w-full" onClick={() => dispatch(closeSidebar())}>
                        {item.name}
                    </span>
                </Link>
                {hasSubMenu.length > 0 && (
                    <div
                        className="cursor-pointer w-full h-8 text-[17px] px-5 flex-shrink-0 flex items-center justify-end text-skin-base text-opacity-80 absolute end-0 top-1/2 transform -translate-y-1/2"
                        onClick={() => handleArrowClick(menuName)}
                    >
                        <IoIosArrowDown
                            className={`transition duration-200 ease-in-out transform ${activeMenus.includes(menuName) ? '-rotate-180' : 'rotate-0'
                                }`}
                        />
                    </div>
                )}
            </div>
            {hasSubMenu.length > 0 && (
                <SubMenu
                    dept={dept}
                    data={item.subcategories}
                    toggle={activeMenus.includes(menuName)}
                    menuIndex={menuIndex}
                />
            )}
        </li>
    )



    return (
        <>
            <div className="flex flex-col justify-between w-full h-full">
                <div className="w-full border-b border-skin-base flex justify-between items-center relative ps-5 md:ps-7 flex-shrink-0 py-0.5">
                    <div role="button" onClick={() => dispatch(closeSidebar())} className="inline-flex">
                        <Logo />
                    </div>

                    <button
                        className="flex text-2xl items-center justify-center px-4 md:px-5 py-5 lg:py-8 focus:outline-none transition-opacity hover:opacity-60"
                        onClick={() => dispatch(closeSidebar())}
                        aria-label="close"
                    >
                        <IoClose className="text-skin-base mt-0.5" />
                    </button>
                </div>
                <Scrollbar className="menu-scrollbar flex-grow mb-auto">
                    <div className="flex flex-col py-6 px-0 text-skin-base">
                        <ul className="mobile-menu">

                            {data?.categories.map((menu, index) => {
                                const dept = 1;
                                const menuName = `sidebar-menu-${dept}-${index}`;
                                return (
                                    <ListMenu
                                        dept={dept}
                                        item={menu}
                                        hasSubMenu={menu.subcategories}
                                        menuName={menuName}
                                        key={menuName}
                                        menuIndex={index}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </Scrollbar>
            </div>
        </>
    )
}