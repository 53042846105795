import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    view: undefined,
    data: null,
    isOpen: false
}

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action) => {
            const { view, data } = action.payload
            state.view = view
            state.data = data
            state.isOpen = true
        },
        closeModal: (state) => {
            state.view = undefined
            state.data = null
            state.isOpen = false
        }
    }
})

export const { openModal, closeModal } = modalSlice.actions
export default modalSlice.reducer