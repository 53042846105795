import { RadioGroup } from '@headlessui/react';
import { TiTimes } from 'react-icons/ti';
import { AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../store/slices/modal';
import { selectAddress } from '../../store/slices/checkout';
import { useAuth } from '../../store/useAuth';
import { useDeleteAddressMutation } from '../../services/slices/addresses';
import { useGetAddressQuery } from '../../services/slices/client';

function AddressGrid({ address, default_address }) {
    const dispatch = useDispatch()
    const auth = useAuth()
    const [deleteAddress] = useDeleteAddressMutation()
    const { refetch } = useGetAddressQuery(auth.user?.id)

    function handlePopupView(item = null) {
        dispatch(openModal({view:'ADDRESS_VIEW_AND_EDIT', data:item}))
      }
    const handleDelete = (item) => {
        deleteAddress({uid: auth.user.id ,did:item.id})
        refetch()
    }

    const checkout = useSelector((state)=> state.checkout)
    const indexByAddressId =  address.findIndex(function(addrss){ return addrss.id === checkout.address?.id})

    
    
    return (
        <div className="text-15px h-full flex flex-col justify-between -mt-4 md:mt-0">
            <RadioGroup
                value={address[indexByAddressId]}
                onChange={(address) => dispatch(selectAddress({ address }))}
                className="md:grid md:grid-cols-2 md:gap-5 auto-rows-auto space-y-4 md:space-y-0"
            >
                <RadioGroup.Label className="sr-only">Address</RadioGroup.Label>
                {address?.length > 0 ? (
                    address?.map((item, index) => (
                        <RadioGroup.Option
                            key={index}
                            value={item}
                            className={({ checked }) =>
                                `${checked ? 'border-skin-primary' : 'border-skin-base'}
                  border-2 relative shadow-md focus:outline-none rounded-md p-5 block cursor-pointer min-h-[112px] h-full group address__box`
                            }
                        >
                            <RadioGroup.Label
                                as="h3"
                                className="text-skin-base font-semibold mb-2 -mt-1"
                            >
                                {item.comuna}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                                as="div"
                                className="text-skin-muted leading-6"
                            >
                                {item.direccion} 

                            </RadioGroup.Description>
                            <RadioGroup.Description
                                as="div"
                                className="text-skin-muted leading-6"
                            >
                                {item.numero} {item.instrucciones}
                            </RadioGroup.Description>
                            <div className="flex absolute right-3 top-3 z-10 lg:opacity-0 transition-all address__actions">
                                <button
                                    onClick={() => handleDelete(item)}
                                    className="flex justify-center items-center bg-skin-red h-6 w-6 rounded-full text-skin-inverted text-opacity-80 text-base"
                                >
                                    <span className="sr-only">Eliminar</span>
                                    <TiTimes />
                                </button>
                            </div>
                        </RadioGroup.Option>
                    ))
                ) : (
                    <div className="border-2 border-skin-base rounded font-semibold p-5 px-10 text-skin-red flex justify-start items-center min-h-[112px] h-full">
                        No hay direcciones registradas
                    </div>
                )}
                <button
                    className="w-full border-2 transition-all border-skin-base rounded font-semibold p-5 px-10 cursor-pointer text-skin-primary flex justify-start hover:border-skin-primary items-center min-h-[112px] h-full"
                    onClick={handlePopupView}
                >
                    <AiOutlinePlus size={18} className="me-2" />
                    Agregar
                </button> 
            </RadioGroup>

           {/*  <div className="flex sm:justify-end mt-5 md:mt-10 lg:mt-20 save-change-button">
                <Button disabled={true} className="w-full sm:w-auto">Guardar</Button>
            </div> */}

        </div>

    )
}

export default AddressGrid