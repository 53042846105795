import cn from "classnames"
import { useSelector } from "react-redux"
import Container from "../../../components/ui/container"
import Logo from "../../../components/ui/logo"


function HeaderSinglePage(props) {
    const ui = useSelector((state) => state.ui)
    return (
        <header id='siteHeader' className={cn(
            'header-two sticky-header sticky top-0 z-20 w-full h-16 lg:h-auto',
            ui.displayMobileSearch && 'active-mobile-search'
        )}>
            <div className="innerSticky w-screen lg:w-full transition-all duration-200 ease-in-out body-font bg-skin-secondary z-20">
            <Container className="top-bar h-16 lg:h-auto flex items-center justify-between py-3">
                    <Logo className="logo -mt-1.5 md:-mt-1" />
            </Container>
            </div>
        </header>
    )
}
export default HeaderSinglePage;