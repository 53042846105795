import { NavLink } from "react-router-dom"
import AccountOrderIcon from "../../icons/account-order"
import AccountSettingsIcon from "../../icons/account-settings"
import LogoutButton from "./LogoutButton"
import cn from "classnames"
import AccountAddressIcon from "../../icons/account-address"
import AccountCardIcon from "../../icons/account-payments"

function AccountNav({ options }) {
    return (
        <nav className="flex flex-col pb-2 md:pb-6 border border-skin-base rounded-md overflow-hidden">

            { options.map((item)=> {
                return (
                    <NavLink key={item.slug} to={item.slug} 
            className={({ isActive }) => cn('flex items-center cursor-pointer text-sm lg:text-15px text-skin-base py-3.5 px-3.5 xl:px-4 2xl:px-5 mb-1', {'bg-skin-two font-medium': isActive })}
            >
                    <span className="w-9 xl:w-10 flex-shrink-0 flex justify-center">
                        {item.icon}
                    </span>
                    <span className="ps-1.5">{item.name}</span>
            </NavLink>
                )
            })
            }
            <LogoutButton/>
        </nav>
    )
}

export default AccountNav