import SignUpForm from "../../components/auth/sign-up-form"
import Divider from "../../components/ui/divider"

const SignUpPage = () => {
    return (
        <>
            <Divider />
            <div className="flex justify-center items-center">
                <div className="py-12 sm:py-16 lg:py-20">
                    <SignUpForm
                        isPopup={false}
                        className="border border-skin-base rounded-lg"
                    />
                </div>
            </div>
            <Divider />
        </>
    )
}

export default SignUpPage