import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../../store/slices/modal"
import ForgetPasswordForm from "../auth/forget-password-form"
import LoginForm from "../auth/login-form"
import SignUpForm from "../auth/sign-up-form"
import ProductPopup from "../Products/product-popup"
import AddAddressForm from "./form/add-address"
import Modal from "./modal/modal"

const AddressPopup = () => <AddAddressForm/>

const ManagedModal = () => {
    const dispatch = useDispatch()
    const modal = useSelector((state) => state.modal)
    return (
        <Modal open={modal.isOpen} onClose={() => dispatch(closeModal())}>
            {modal.view === 'ADDRESS_VIEW_AND_EDIT' && <AddressPopup />}
            {modal.view === 'PRODUCT_VIEW' && <ProductPopup />}
            {modal.view === 'FORGET_PASSWORD' && <ForgetPasswordForm/>}
            {modal.view === 'LOGIN_VIEW' && <LoginForm/>}
            {modal.view === 'SIGN_UP_VIEW' && <SignUpForm/>}
        </Modal>
    )
}

export default ManagedModal