import React, { useState } from 'react';
import {
  GoogleMap,
  MarkerF,
  InfoWindow,
} from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'

import { Combobox } from '@headlessui/react';

const inputClasses = "py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12 transition duration-200 ease-in-out text-skin-base focus:shadow w-full bg-skin-fill border-skin-two focus:border-2 focus:outline-none focus:border-skin-primary h-11 md:h-12"

export const PlaceAutocomplete = ({ setSelected,mapCurrentPosition }) => {
  const { value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete()

  const handleChange = async (address) => {
    setValue(address, false);
    clearSuggestions();
    console.log(address)

    const result = await getGeocode({address})
    const {lat, lng } = await getLatLng(result[0])
    setSelected({lat,lng})
    mapCurrentPosition(result[0])
  }
  return (
    <>
      <Combobox value={value} onChange={handleChange}>
        <Combobox.Input onChange={(event) => setValue(event.target.value)} className={inputClasses} placeholder="Dirección"/>
        <div className="relative w-full font-body">
          <Combobox.Options className="absolute z-20 w-full py-2.5 mt-1.5 overflow-auto bg-skin-fill rounded-md shadow-dropDown max-h-72 focus:outline-none text-sm md:text-15px">
            {status === "OK" && data.map(({ place_id, description }) => <Combobox.Option className={({ active }) =>
              `cursor-pointer relative py-3 px-4 md:px-5 ${active
                ? 'text-skin-base bg-skin-dropdown-hover'
                : 'text-skin-base'
              }`
            } key={place_id} value={description}>{description}</Combobox.Option >)}
          </Combobox.Options>
        </div>
      </Combobox>

    </>
  )
}

const Map = ({
  lat,
  lng,
  height,
  zoom,
  showInfoWindow,
  mapCurrentPosition,
  isLoaded,
  extra
}) => {
  const containerStyle = {
    width: '100%',
    height: height || '420px',
  };

  const center = {
    lat: lat || -33.4429091,
    lng: lng || -70.6538699,
  };

  const [selectedMarker, setSelectedMarker] = useState();
  const [mapPosition, setMapPosition] = useState(null);
  const [infoWindowToggle, setInfoWindowToggle] = useState(false);

  const onMarkerDragEnd = (e) => {
    if (e.domEvent.type === 'click') {
      setInfoWindowToggle(true);
    }
    const geocoder = new (window).google.maps.Geocoder();
    const latLng = {
      lat: parseFloat(e.latLng.lat()),
      lng: parseFloat(e.latLng.lng()),
    };
    geocoder
      .geocode({ location: latLng })
      .then((response) => {
        if (response.results[0]) {
          if (mapCurrentPosition !== undefined) {
            mapCurrentPosition(response.results[0]?.formatted_address);
          }
          setSelectedMarker(response.results[0]);
          setMapPosition(latLng);
          setInfoWindowToggle(true);
        } else {
          window.alert('No results found');
        }
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e));
  };


  return isLoaded ? (
    <>
      <div className="mb-6">
        <PlaceAutocomplete setSelected={setMapPosition} mapCurrentPosition={mapCurrentPosition}/>
        {extra}
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapPosition || center}
        zoom={!mapPosition ? zoom : 19}
      >
        <MarkerF
          position={mapPosition || center}
          draggable={true}
          visible={true}
          icon={'/assets/images/pin.png'}
          onDragEnd={(e) => onMarkerDragEnd(e)}
          onClick={(e) => onMarkerDragEnd(e)}
        >
          {showInfoWindow && infoWindowToggle && (
            <InfoWindow
              position={mapPosition || center}
              onCloseClick={() => setInfoWindowToggle(false)}
            >
              <p>{selectedMarker?.formatted_address}</p>
            </InfoWindow>
          )}
        </MarkerF>
      </GoogleMap>
    </>
  ) : (
    <div>Loading....</div>
  );
};

export default React.memo(Map);


