import { configureStore } from '@reduxjs/toolkit'
import cartReducer from './slices/cart'
import authReducer from './slices/auth'
import uiReducer from './slices/ui'
import modalReducer from './slices/modal'
import checkoutReducer from './slices/checkout'
import addressReducer from './slices/address'
import callbackReducer from './slices/callback'
import { loadState } from './local'
import { lystoApi } from '../services/config'
import cartListenerMiddleware from './cartMiddleware'
import analyticsListenerMiddleware from './analyticsMiddleware'

const store = configureStore({
    devTools: true,
    reducer: {
        cart: cartReducer,
        auth: authReducer,
        ui: uiReducer,
        modal: modalReducer,
        checkout: checkoutReducer,
        address: addressReducer,
        callback: callbackReducer,
        [lystoApi.reducerPath]: lystoApi.reducer,
    },
    preloadedState: loadState(),

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
    serializableCheck: false,
        }).concat(
            [
                lystoApi.middleware,
                cartListenerMiddleware.middleware,
                analyticsListenerMiddleware.middleware
            ])

})
export default store
