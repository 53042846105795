import { scheduleTimeFrameByDate } from "../../utils/datetime-format"
import { lystoApi } from "../config"

export const timeFrameApi = lystoApi.injectEndpoints({
    endpoints: (build) => ({
        getVentanaHoraria: build.query({
            query: () => 'VentanaHoraria',
            transformResponse: (response, meta, arg) => {
                return scheduleTimeFrameByDate(response)
            }
        }),
        
    }),
    overrideExisting: false,
})


export const { useGetVentanaHorariaQuery } = timeFrameApi