import { NavLink } from "react-router-dom"

const Breadcrumbs = ({ items }) => {
    let breadcrumb = [{ name: 'Home', url: '/' }]

    if (items) {
        breadcrumb.push(...items)
    }

    return (
        <div className="text-sm py-3">
            {
                breadcrumb.map(crumb => {
                    return (
                        <NavLink to={crumb.url} key={crumb.name} className="[&:not(:last-child)]:after:content-['/'] after:p-1 ">{crumb.name}</NavLink>
                    )
                })
            }
        </div>
    )
}

export default Breadcrumbs
