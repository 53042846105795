import { useEffect, useState } from "react"
import { RadioGroup } from '@headlessui/react';
import { useGetVentanaHorariaQuery } from "../../services/slices/timeframe"
import { filterAvailable, scheduleTimeFrameByDate } from "../../utils/datetime-format"
import cn from 'classnames'
import { selectTimeFrame } from "../../store/slices/checkout";
import { useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";



export default function Schedule() {
    const dispatch = useDispatch()
    const { data, error, isLoading } = useGetVentanaHorariaQuery({},{
        refetchOnMountOrArgChange: true
      })
    const checkout = useSelector((state)=> state.checkout)
    const [dateSchedule, setDateSchedule] = useState(checkout.timeFrame?.fecha?.split("T")[0])
    const indexByTimeFrameId =  data?.[dateSchedule]?.findIndex(function(timeFrame){ return timeFrame.slug === checkout.timeFrame?.slug})
    const getDayAndMonth = (date) => {
        return new Date(date)
    }


    return (
        <div className="w-full">
            {
                isLoading ? <h3>Cargando...</h3> :

                    <div className="w-full  mx-auto">
                        <RadioGroup value={dateSchedule} onChange={setDateSchedule}>
                            <RadioGroup.Label className="sr-only">
                                Calendario de entrega
                            </RadioGroup.Label>
                            <div className="grid gap-4 grid-cols-2 sm:grid-cols-4 lg:grid-cols-6">
                                {Object.keys(data).map((date) => (
                                    <RadioGroup.Option
                                        key={date}
                                        value={date}
                                        className={({ active, checked }) =>
                                            cn(
                                                'relative rounded-lg shadow-md px-5 py-3 cursor-pointer focus:outline-none',
                                                checked
                                                    ? 'bg-skin-primary text-skin-inverted'
                                                    : 'bg-gray-100'
                                            )
                                        }
                                    >
                                        {({ checked }) => (
                                            <div className="text-center">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`text-base font-semibold  ${checked ? 'text-skin-inverted' : 'text-gray-900'
                                                        }`}
                                                >
                                                    {getDayAndMonth(date).toLocaleString('es-CL', { weekday: "short", timeZone: "UTC" } )}
                                                </RadioGroup.Label>
                                                <RadioGroup.Description
                                                    as="span"
                                                    className={`text-15px ${checked ? 'text-skin-inverted' : 'text-gray-500'
                                                        }`}
                                                >
                                                    {getDayAndMonth(date).toLocaleString('es-CL', { month: "short", day: "numeric" , timeZone: "UTC"})}
                                                </RadioGroup.Description>
                                            </div>
                                        )}

                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>

                        <RadioGroup
                            className="mt-10"
                            value={data?.[dateSchedule]?.[indexByTimeFrameId]}
                            onChange={(timeFrame) => dispatch(selectTimeFrame({timeFrame}))}
                        >
                            <RadioGroup.Label className="sr-only">
                                Horario de entrega
                            </RadioGroup.Label>
                            { dateSchedule ? 
                            <div className="flex justify-between flex-wrap lg:grid gap-4 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
                                {data[dateSchedule].length > 0  ? data[dateSchedule].map((time, index) => (
                                              <RadioGroup.Option
                                              key={time.slug}
                                              value={time}
                                              className="cursor-pointer focus:outline-none"
                                            >
                                              {({ active, checked }) => (
                                                <div className="flex items-center">
                                                  <span
                                                    className={cn(
                                                      'flex w-6 h-6 rounded-full',
                                                      checked
                                                        ? 'border-[6px] border-skin-primary'
                                                        : 'border-2 border-gray-200'
                                                    )}
                                                  />
                                                  <RadioGroup.Label
                                                    as="p"
                                                    className="text-sm text-black ms-2"
                                                  >
                                                    {`${time.horaInicio}:00 - ${time.horaFin}:00`}
                                                  </RadioGroup.Label>
                                                </div>
                                              )}
                                            </RadioGroup.Option>
                                )):
                                <>Sin horario disponible para este día. </>}
                            </div> : <h3>Selecciona una fecha</h3>
                            }
                            
                        </RadioGroup>
                    </div>
            } </div>
    )
}