import { useParams } from "react-router-dom"
import { useGetOrderByIdQuery } from "../../services/slices/order"
import priceFormatter from "../../utils/price-formater"
import Heading from "../ui/heading"


const OrderItemCard = ({ product }) => {
    return (
        <tr
            className="border-b font-normal border-skin-base last:border-b-0"
            key={product.id}
        >
            <td className="p-4">
                {product.name} * {product.cantidad}
            </td>
            <td className="p-4">{priceFormatter.format(product.precioTotal)}</td>
        </tr>
    )
}

const OrderDetails = ({
    className = 'pt-10 lg:pt-12 pb-10 lg:pb-12',
}) => {
    const { id } = useParams()
    const { data: order, isSuccess, error, isLoading } = useGetOrderByIdQuery(id)
    
    if (isLoading) return <p>Loading...</p>;
    return (
        <div className={className}>
            <Heading variant="heading" className="mb-6 xl:mb-7">
                Detalles:
            </Heading>
            <table className="w-full text-skin-base font-semibold text-sm lg:text-base">
                <thead>
                    <tr>
                        <th className="bg-skin-secondary p-4 text-start first:rounded-ts-md w-1/2">
                            Producto
                        </th>
                        <th className="bg-skin-secondary p-4 text-start last:rounded-te-md w-1/2">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {order?.productos.map((product, index) => (
                        <OrderItemCard key={index} product={product} />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="odd:bg-skin-secondary">
                        <td className="p-4 italic">Subtotal:</td>
                        <td className="p-4">{priceFormatter.format(order?.subtotals_metadata?.amountAfterDiscount)}</td>
                    </tr>
                    <tr className="odd:bg-skin-secondary">
                        <td className="p-4 italic">Envio:</td>
                        <td className="p-4">
                            {priceFormatter.format(order?.despacho?.costoDespacho)}
                        </td>
                    </tr>
                    <tr className="odd:bg-skin-secondary">
                        <td className="p-4 italic">Metodo de pago:</td>
                        <td className="p-4">{order?.mediopago?.creditCardType} {order?.mediopago?.last4CardDigits}</td>
                    </tr>
                    <tr className="odd:bg-skin-secondary">
                        <td className="p-4 italic">Total:</td>
                        <td className="p-4">{priceFormatter.format(order?.precioTotal)}</td>
                    </tr>
                    <tr className="odd:bg-skin-secondary">
                        <td className="p-4 italic">Nota:</td>
                        <td className="p-4">{order?.comment}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default OrderDetails