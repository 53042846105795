import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"
import { lystoApi } from "../services/config"
import { productsCartExportFormat } from "../utils/use-cart"
import { setCredentials } from "./slices/auth"
import { clearCart, decreaseItemQty, increaseItemQty, removeProduct } from "./slices/cart"

const cartListenerMiddleware = createListenerMiddleware()

cartListenerMiddleware.startListening({
    matcher: isAnyOf(increaseItemQty, decreaseItemQty),
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState()
        if (state.auth.user){
            const uid = state.auth.user.id
            const pid = action.payload.item?.id
            const cantidad = state.cart.productsQty[pid]

            listenerApi.dispatch(
                lystoApi.endpoints.syncOneRemoteCart.initiate({uid,pid,cantidad})
              )
            }
    }
})

cartListenerMiddleware.startListening({
    actionCreator: removeProduct,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState()
        if (state.auth.user){
            const uid = state.auth.user.id
            const pid = action.payload.item?.id
            
            listenerApi.dispatch(
                lystoApi.endpoints.deleteOneRemoteCart.initiate({uid,pid})
            )
        }
        
    }
    
})

cartListenerMiddleware.startListening({
    actionCreator: clearCart,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState()
        if (state.auth.user){
            const uid = state.auth.user.id
            listenerApi.dispatch(
                lystoApi.endpoints.deleteRemoteCart.initiate(uid)
            )
            
        }
    }
})

cartListenerMiddleware.startListening({
    actionCreator: setCredentials,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState()
        const uid = state.auth.user.id
        const {isSuccess} = await listenerApi.dispatch(
            lystoApi.endpoints.getRemoteCart.initiate(uid)
        )
        const products = productsCartExportFormat(state.cart)
        if(isSuccess){
            await listenerApi.dispatch(
                lystoApi.endpoints.deleteRemoteCart.initiate(uid)
            )
            await listenerApi.dispatch(
                lystoApi.endpoints.syncManyRemoteCart.initiate({uid,products})
            )
        }
    }
})
export default cartListenerMiddleware