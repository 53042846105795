import { useState } from "react"
import { useSetCouponMutation } from "../../services/slices/cart"
import { useAuth } from "../../store/useAuth"
import Button from "../ui/button"
import Input from "../ui/form/input"


const FormCoupon = ({disabled, value, onClick, isLoading, onChange, textButton, placeholder }) => {
    return (
    <>
        <input placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} className="py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12 transition duration-200 ease-in-out text-skin-base"/>
        <button disabled={isLoading} onClick={onClick} className="text-[13px] md:text-sm lg:text-15px justify-center font-body font-semibold rounded placeholder-white bg-skin-primary px-4 text-skin-inverted">{textButton}</button>
    </>
    )
}

const CouponBox = ({ coupon }) => {

    const auth = useAuth()
    const [setCoupon, { data, isSuccess, isLoading }] = useSetCouponMutation()
    const [couponCode, setCouponCode] = useState("")

    const applyCoupon = () => {
        const payload = { uid: auth.user?.id, codigoDescuento: couponCode }
        setCoupon(payload)
    }

    const removeCoupon = () => {
        const payload = { uid: auth.user?.id, codigoDescuento: null }
        setCoupon(payload)
        setCouponCode("")
    }

    const setForm = () => {
        if (coupon) {
            return (<FormCoupon disabled={true} value={coupon.codigo} onClick={removeCoupon} isLoading={isLoading} onChange={(e) => setCouponCode(e.target.value)} textButton="Quitar"/>)
        } else {
            return (<FormCoupon disabled={false} value={couponCode} onClick={applyCoupon} isLoading={isLoading} onChange={(e) => setCouponCode(e.target.value)} textButton="Agregar" placeholder="CUPON"/>)
        }
    }

    

    return <div className="flex justify-between space-x-2">
        {setForm()}
    </div>
}

export default CouponBox