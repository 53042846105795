import priceFormatter from "../../utils/price-formater";

const CheckoutCardFooter = ({
    preview,
}) => {

    return (<>
    
    <div className="flex items-center py-4 lg:py-5 border-b border-skin-base text-sm w-full text-15px text-skin-base font-medium  last:border-b-0 last:text-base last:pb-0">
            Subtotal
            <span className="ms-auto flex-shrink-0 text-15px text-skin-base font-normal line-through">
                {priceFormatter.format(preview?.precioNeto)}
            </span>
        </div>

        <div className="flex items-center py-4 lg:py-5 border-b border-skin-base text-sm w-full text-15px text-skin-base font-medium  last:border-b-0 last:text-base last:pb-0">
            Despacho
            <span className="ms-auto flex-shrink-0 text-15px text-skin-base font-normal">
                {priceFormatter.format(preview?.costoDespacho)}
            </span>
        </div>
        <div className="flex items-center py-4 lg:py-5 border-b border-skin-base text-sm w-full text-15px text-skin-base font-medium  last:border-b-0 last:text-base last:pb-0">
            Total
            <span className="ms-auto flex-shrink-0 text-15px text-skin-base font-normal">
                {priceFormatter.format(preview?.precioTotal)}
            </span>
            
        </div>
    
    </>

    );
};

export default CheckoutCardFooter