import { useEffect } from "react"
import { Element } from "react-scroll"
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs"
import CategoryDropdownSidebar from "../../components/category/category-dropdown-sidebar"
import CategoryHeroSection from "../../components/category/category-hero-section"
import ProductBundleGrid from "../../components/Products/ProductBundleGrid"
import Container from "../../components/ui/container"
import Divider from "../../components/ui/divider"
import { useGetOffersQuery } from "../../services/slices/product"
import { Helmet } from "react-helmet"

const with_stock = (products) => {
    return  products.filter(function (product) {
        return product.stock > 0
      });
}

function OffersPage() {
    const { data, isLoading, error, isSuccess } = useGetOffersQuery({}, { refetchOnMountOrArgChange: true })
    useEffect(() => {
        console.log(data)
    }, [data])
    return (
        <Container> {isSuccess && <>
            <Helmet>
                <title>Ofertas - Lysto</title>
            </Helmet>
            <Breadcrumbs items={[{ name: 'Ofertas', url: '/category/offers' },]} />
            <Element name="grid" className="flex mb-5 pb-1">
                <CategoryDropdownSidebar className="flex-shrink-0 pe-4 xl:pe-4 hidden lg:block w-80 xl:w-[320px]" />
                <div className="minimal-main-content w-full xl:-ms-8">
                    <CategoryHeroSection heroTitle={data[0]?.name} />

                    <Divider />
                    <ProductBundleGrid
                        className="pt-3" data={with_stock(data[0]?.ProductoTipoConsumible)} error={error} isLoading={isLoading} />
                </div>
            </Element>
        </>}

        </Container>
    )
}

export default OffersPage