export const siteSettings = {
    name: 'Lysto',
    description:
      'El Supermercado como debe ser: Online con envío gratis, precios rebajados, tus marcas de siempre y las emergentes también.',
    author: {
      name: 'Lysto',
      websiteUrl: 'https://lysto.cl',
      address: '',
    },
    maps: {
      google_maps_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    },
    payments: {
      oneclick: process.env.REACT_APP_ONECLICK_HANDLE_URL
    },
    logo: {
        url: '/assets/images/logo-lysto.png',
        alt: 'Lysto',
        href: '/',
        width: 128,
        height: 30,
      },
      site_header: {
        menu: [
         /*  {
            id: 1,
            path: '/',
            label: 'Ofertas',
          },
          {
            id: 2,
            path: '/',
            label: 'Destacado',
            subMenu: [
              {
                id: 1,
                path: '/',
                label: 'Uno',
              },
              {
                id: 2,
                path: '/',
                label: 'Dos',
              },
              {
                id: 3,
                path: '/',
                label: 'Tres',
              },

            ],
          },
          {
            id: 2,
            path: '/',
            label: 'Destacado 2',
            subMenu: [
              {
                id: 1,
                path: '/',
                label: 'Uno',
              },
              {
                id: 2,
                path: '/',
                label: 'Dos',
              },
              {
                id: 3,
                path: '/',
                label: 'Tres',
              },

            ],
          },
          {
            id: 4,
            path: '/',
            label: 'Destacado 3',
          }, */
        ]}
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};