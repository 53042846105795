import Copyright from "./copyright"
import { footer } from "./data"
import Widgets from "./widget/widgets"
const { payment } = footer;

const Footer = () => {
    return (
      <footer className="mt-[50px] lg:mt-14 2xl:mt-16">
        <Widgets />
    <Copyright payment={payment}/>
  </footer>
    )
}

export default Footer