import { lystoApi } from "../config"

export const addressesApi = lystoApi.injectEndpoints({
    endpoints: (build) => ({
        addAddress: build.mutation({
            query: ({uid, params}) => ({
                url: `cliente/${uid}/direcciones`,
                method: 'POST',
                body: params,
            }), invalidatesTags: ['Addresses']
        }),
        deleteAddress: build.mutation({
            query: ({uid, did})=> ({
                url: `Cliente/${uid}/direcciones/${did}`,
                method: 'DELETE',
            })
        })
    })
})
export const { useAddAddressMutation, useDeleteAddressMutation } = addressesApi