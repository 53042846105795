import { logEvent } from "@firebase/analytics"
import { analytics } from ".."

export const createCheckoutLogAnalytics = async (arg, payload) => {
    if (arg.endpointName === "createCheckout") {
        console.log('purchase event')
        const { productos, transaccion: { id, amount } } = payload;
        logEvent(analytics, 'purchase_web', {
            currency: 'CLP',
            item_id: id.toString(),
            value: amount,
            items: productos.map(product => ({
                item_id: product.id.toString(),
                item_name: product.name,
                quantity: product.cantidad,
                currency: 'CLP',
                price: product.effectivePrice,
              })),
        })
        
        window.fbq('track', 'Purchase', {
            value: amount,
            currency: 'CLP',
            contents: productos.map(product => ({
                id: product.id.toString(),
                quantity: product.cantidad,
              })),
            content_type: 'product'
          });
    }
}

//export const initCheckoutLog= async (arg, payload) => {
//  if (arg.endpointName === "createCheckout") {
//  }}