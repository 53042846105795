import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ?
    'https://api-dev.lysto.app/api' :
    'https://webapp.lysto.cl/api'

export const lystoApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Accept', 'application/json');
            const token = getState().auth.token
            if (token) {
                headers.set('Authorization', `${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['Client', 'Misc', 'Product', 'Account', 'Order', 'Addresses', 'PaymentMethods'],
    endpoints: () => ({}),
    refetchOnMountOrArgChange: true,
})

