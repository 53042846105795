import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux"
import { useForgetPasswordMutation } from "../../services/slices/client";
import { closeModal, openModal } from "../../store/slices/modal"
import Button from "../ui/button";
import CloseButton from "../ui/close-button"
import Input from "../ui/form/input";
import Logo from "../ui/logo"

const defaultValues = {
    email: '',
};

const ForgetPasswordForm = () => {
    const dispatch = useDispatch()
    const [forgetPassword, { data, isLoading, isSuccess, error }] = useForgetPasswordMutation()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues,
    });
    const onSubmit = (values) => {
        forgetPassword(values)
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(closeModal())
        }
        console.log(error)
    }, [isLoading, isSuccess, error, data])

    const handleSignIn = () => {
        dispatch(openModal({view: 'LOGIN_VIEW'}))
    }
    return (
        <div className="py-6 px-5 sm:p-8 bg-skin-fill mx-auto rounded-lg w-full sm:w-96 md:w-450px">
            <CloseButton onClick={() => dispatch(closeModal())} />
            <div className="text-center mb-9 pt-2.5">

                <div onClick={() => dispatch(closeModal())}>
                    <Logo />
                </div>
                <p className="text-sm md:text-base text-body mt-3 sm:mt-4 mb-8 sm:mb-10">
                    Te enviaremos un enlace para restablecer tu contraseña
                </p>
            </div>
            <form
                onSubmit={handleSubmit((data) => onSubmit(data))}
                className="flex flex-col justify-center"
                noValidate
            >

                <Input
                    label={'Email'}
                    type="email"
                    variant="solid"
                    className="mb-4"
                    {...register('email', {
                        required: `Debe proporcionar su dirección de correo electrónico`,
                        pattern: {
                            value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Proporcione una dirección de correo electrónico válida',
                        },
                    })}
                    error={errors.email?.message}
                />

                <Button
                    type="submit"
                    variant="formButton"
                    className="h-11 md:h-12 w-full mt-0"
                >
                    Restablecer contraseña
                </Button>
            </form>
            <div className="flex flex-col items-center justify-center relative text-sm text-heading mt-8 sm:mt-10 mb-6 sm:mb-7">
                <hr className="w-full border-gray-300" />
                <span className="absolute -top-2.5 px-2 bg-skin-fill">
                    o
                </span>
            </div>
            <div className="text-sm sm:text-15px text-skin-muted text-center">
                {'volver a'}{' '}
                <button
                    type="button"
                    className="text-skin-base underline font-medium hover:no-underline focus:outline-none"
                    onClick={handleSignIn}
                >
                    {"Iniciar sesión"}
                </button>
            </div>
        </div>
    )
}

export default ForgetPasswordForm