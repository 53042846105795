import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom"
import LayoutPage from './views/Layout/page';
import SignInPage from './views/User/signin';
import AccountDetailsPage from './views/User/my-account/account-settings';
import OrdersTablePage from './views/User/my-account/orders';
import AddressGridPage from './views/User/my-account/address';
import PaymentPage from './views/User/my-account/payment';
import CheckoutPage from './views/checkout';
import CategoryPage from './views/Category/Category';
import OrderPage from './views/order/Order';
import SignUpPage from './views/User/signup';
import TOSPage from './views/Tos/TosPage';
import PrivacyPage from './views/Privacy/PrivacyPage';
import OffersPage from './views/Offers/offers';
import BrandPage from './views/Brand/Brand';
import SearchResults from './views/Search/Search';
import LayoutSinglePage from './views/Layout/singlePage';
import CajasPage from './views/Single/Cajas';
import Home from "./views/Home/Home";
import Product from "./views/Product/Product";
import Cart from './components/Cart/cart';
import NotFound from "./components/404/error-information";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route element={<LayoutPage />}>
                <Route path="/*" element={<NotFound />} />

                <Route path="/" element={<Home />} />
                <Route path="product">
                    <Route path=":id" element={<Product />} />
                </Route>
                <Route path="category">
                    <Route path="offers" element={<OffersPage />} />
                    <Route path=":id" element={<CategoryPage />} />
                </Route>

                <Route path="brand">
                    <Route path=":id" element={<BrandPage />} />
                </Route>

                <Route path="/cart" element={<Cart />} />
                <Route path="/cart/checkout" element={<CheckoutPage />} />
                <Route path="/order">
                    <Route path=":id" element={<OrderPage />} />
                </Route>
                <Route path="/login" element={<SignInPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/mi-cuenta">
                    <Route index element={<AccountDetailsPage />} />
                    <Route path="datos" element={<AccountDetailsPage />} />
                    <Route path="direcciones" element={<AddressGridPage />} />
                    <Route path="pedidos" element={<OrdersTablePage />} />
                    <Route path="medios-pago" element={<PaymentPage />} />
                </Route>
                <Route path="/legal">
                    <Route path="terminos-y-condiciones" element={<TOSPage />} />
                    <Route path="privacidad" element={<PrivacyPage />} />
                </Route>

                <Route path="/search" element={<SearchResults />} />
            </Route>
            <Route element={<LayoutSinglePage />}>
                    <Route path="/cajas" element={<CajasPage />} />
            </Route> 
        </Route>
    ))



export default router