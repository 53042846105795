import Drawer from "rc-drawer"
import 'rc-drawer/assets/index.css';
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { openModal } from "../../../store/slices/modal";
import { closeMobileSearch, closeSidebar, openMobileSearch, openSidebar } from "../../../store/slices/ui"
import { useAuth } from "../../../store/useAuth"
import { ROUTES } from "../../../utils/routes"
import CartButton from "../../Cart/cart-button"
import HomeIcon from "../../icons/home-icon"
import MenuIcon from "../../icons/menu-icon"
import SearchIcon from "../../icons/search-icon";
import UserIcon from "../../icons/user-icons"
import AuthMenu from "../../User/AuthMenu"
import MobileMenu from "./mobile-menu";

const MobileNavigation = () => {
    const auth = useAuth()
    const ui = useSelector((state) => state.ui)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const contentWrapperCSS = { left: 0 };

    const handleLogin = () => {
        dispatch(openModal({view:'LOGIN_VIEW'}))
    }

    const toggleMobileSearch = () =>
    ui.displayMobileSearch
      ? dispatch(closeMobileSearch())
      : dispatch(openMobileSearch());
    return (
        <>
            <div className="lg:hidden fixed z-30 -bottom-0.5 flex items-center justify-between shadow-bottomNavigation body-font bg-skin-fill w-full h-14 px-4 md:px-6 lg:px-8 text-skin-muted pb-0.5">

                <button
                    aria-label="Menu"
                    className="flex flex-col items-center justify-center flex-shrink-0 outline-none focus:outline-none"
                    onClick={() => dispatch(openSidebar())}
                >
                    <MenuIcon />
                </button>
                <button
                    className="flex items-center justify-center flex-shrink-0 h-auto relative focus:outline-none"
                    onClick={toggleMobileSearch}
                    aria-label="Search Button"
                >
                    <SearchIcon />
                </button>
                <Link to={ROUTES.HOME} className="flex-shrink-0">
                    <span className="sr-only">Inicio</span>
                    <HomeIcon />
                </Link>
                <CartButton hideLabel={true} iconClassName="text-opacity-100" />

                <AuthMenu
                    isAuthorized={auth.user}
                    href={ROUTES.ACCOUNT}
                    btnProps={{
                        className: 'flex-shrink-0 focus:outline-none',
                        children: <UserIcon />,
                        onClick: handleLogin,
                    }}
                >
                    <UserIcon />
                </AuthMenu>
            </div>
            <Drawer
                placement={'left'}
                open={ui.displaySidebar}
                onClose={() => dispatch(closeSidebar())}
                handler={false}
                showMask={true}
                level={null}
                contentWrapperStyle={contentWrapperCSS}
            >
                <MobileMenu />
            </Drawer>
        </>
    )

}

export default MobileNavigation