import cn from "classnames"
import Input from "../ui/form/input"
import { useForm } from "react-hook-form";
import Logo from "../ui/logo";
import PasswordInput from "../ui/form/password-input";
import Button from "../ui/button";
import { useLoginFbMutation, useLoginMutation } from "../../services/slices/client";
import { setCredentials } from "../../store/slices/auth";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../../store/slices/modal";
import { FaFacebook } from 'react-icons/fa';
import { toast } from 'react-toastify';

import FacebookLogin from '@greatsumini/react-facebook-login';

const LoginForm = ({ isPopup = true, className }) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [login, { data, isLoading, isSuccess, error }] = useLoginMutation()
    const [loginFb, {data: dataFb, isLoading: isLoadingFb, isSuccess: isSuccessFb}] = useLoginFbMutation()  
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = ({ email, password }) => {
        login(
            { email, password })
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(setCredentials(data))
    
        }
        if(isSuccessFb){
            dispatch(setCredentials({user:dataFb.user, id:dataFb.accessToken.id}))
            
        }
        if (error){
            console.log(error)
            toast.error(error.error,  {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }

        if (isSuccess || isSuccessFb ){
            toast.success('Bienvenid@!',  {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            dispatch(closeModal())
            navigate('/')
        }
    }, [isSuccess, isSuccessFb, error])


    const onError = (errors, e) => console.log(errors, e);

    const handleForgetPassword = () => {
        return dispatch(openModal({ view: 'FORGET_PASSWORD' }))
    }
    const handleSignUp = () => {
        return dispatch(openModal({ view: 'SIGN_UP_VIEW' }))
    }

    const onFbSuccess = ({accessToken}) => {
        console.log('Login Success!', accessToken)
        const payload = {access_token: accessToken}
        loginFb(payload)
    }

    return (
        <div
            className={cn(
                'w-full lg:w-[920px] xl:w-[1000px] 2xl:w-[1200px] relative',
                className
            )}
        >
            <div className="flex bg-skin-fill mx-auto rounded-lg overflow-hidden">
                <div className="md:w-[55%] xl:w-[60%] registration hidden md:block">
                    <img
                        src="/assets/images/login.png"
                        alt="signin Image"
                        width={800}
                        height={621}
                        className="w-full"
                    />
                </div>
                <div className="w-full md:w-[45%] xl:w-[40%] py-6 sm:py-10 px-4 sm:px-8 xl:px-12 rounded-md shadow-dropDown flex flex-col justify-center">
                    <div className="text-center mb-6 ">
                        <div>
                            <Logo />
                        </div>
                        <h4 className="text-skin-base font-semibold text-xl sm:text-2xl sm:pt-3 ">
                            Bienvenido!
                        </h4>
                        <div className="text-sm sm:text-15px text-body text-center mt-3 mb-1">
                            ¿Aún no tienes cuenta?
                            <button
                                type="button"
                                className="text-skin-primary sm:text-15px text-sm ms-1 font-semibold  hover:no-underline focus:outline-none"
                                onClick={handleSignUp}
                            >
                                Crear Cuenta
                            </button>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit(onSubmit, onError)}
                        className="flex flex-col justify-center"
                        noValidate
                    >
                        <div className="flex flex-col space-y-3.5">
                            <Input
                                label="Email"
                                type="email"
                                variant="solid"
                                {...register('email', {
                                    required: "Email requerido",
                                    pattern: {
                                        value:
                                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Ingresa un email válido",
                                    },
                                })}
                                error={errors.email?.message}
                            />
                            <PasswordInput
                                label="Contraseña"
                                error={errors.password?.message}
                                {...register('password', {
                                    required: "Contraseña requerida",
                                })}
                            />
                            <div className="flex items-center justify-center">
                                <div className="flex ms-auto mt-[3px]">
                                    <button
                                        type="button"
                                        onClick={handleForgetPassword}
                                        className="text-end text-sm text-heading ps-3  hover:no-underline hover:text-skin-base focus:outline-none focus:text-skin-base"
                                    >
                                        ¿Olvidó la contraseña?
                                    </button>
                                </div>
                            </div>
                            <div className="relative">
                                <Button
                                    type="submit"
                                    loading={isLoading}
                                    disabled={isLoading}
                                    className="h-11 md:h-12 w-full mt-2 font-15px md:font-15px tracking-normal"
                                    variant="formButton"
                                >
                                    Ingresar
                                </Button>
                            </div>
                        </div>
                    </form>
                    <div className="flex flex-col items-center justify-center relative text-sm">
                        <span className="mt-6 text-sm text-skin-base opacity-70">
                            o continua con
                        </span>
                    </div>
                    <div className="flex justify-center mt-5 space-x-2.5">

                        <FacebookLogin
                            appId="445228776315844"
                            language="es_ES"
                            style={{
                                backgroundColor: '#4267b2',
                                color: '#fff',
                                fontSize: '14px',
                                fontWeight: '600',
                                padding: '10px 24px',
                                border: 'none',
                                borderRadius: '4px',
                              }}
                            children="Ingresar con Facebook"
                            onSuccess={onFbSuccess}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LoginForm