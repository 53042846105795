import { useParams } from "react-router-dom"
import { Element } from "react-scroll"
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs"
import CategoryDropdownSidebar from "../../components/category/category-dropdown-sidebar"
import CategoryHeroSection from "../../components/category/category-hero-section"
import ProductBundleGrid from "../../components/Products/ProductBundleGrid"
import Container from "../../components/ui/container"
import Divider from "../../components/ui/divider"
import { useGetCategoryQuery } from "../../services/slices/categories"
import { Helmet } from "react-helmet"

const getCategoryTree = (category) => {
    console.log(category)
    let text = ''
    if (category.parent_name) {
        text = `${category.parent_name} - `
    }
    return { name: `${text.concat(category.name)}`, url: `/category/${category.id}` }

}

function CategoryPage() {
    const { id } = useParams()
    const { data, isLoading, error, isSuccess } = useGetCategoryQuery(id, { refetchOnMountOrArgChange: true })

    return (
        <>
            <Container>
                {Boolean(isSuccess) &&
                    <>
                        <Helmet>
                            <title>{data?.name} - Lysto</title>
                            <meta name="description" content="Supermercado online con envío gratis, precios rebajados, tus marcas de siempre y las emergentes también." />
                        </Helmet>

                        <Breadcrumbs items={[getCategoryTree(data),]} />
                        <Element name="grid" className="flex mb-5 pb-1">
                            <CategoryDropdownSidebar className="flex-shrink-0 pe-4 xl:pe-4 hidden lg:block w-80 xl:w-[320px]" />
                            <div className="minimal-main-content w-full xl:-ms-8">
                                <CategoryHeroSection heroTitle={data?.name} />
                                <Divider />
                                <ProductBundleGrid
                                    className="pt-3" data={data?.ProductoTipoConsumible} error={error} isLoading={isLoading} />
                            </div>
                        </Element>

                    </>}

            </Container>
        </>
    )
}

export default CategoryPage