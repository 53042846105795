import cn from "classnames";
import { useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
    Swiper,
  Navigation,
  Autoplay,
  Pagination,
  Grid,
} from './slider';
const Carousel = ({
    children,
    className = '',
    buttonGroupClassName = '',
    prevActivateId = '',
    nextActivateId = '',
    prevButtonClassName = '-left-3.5 lg:-left-4 xl:-left-5',
    nextButtonClassName = '-right-3.5 lg:-right-4 xl:-right-5',
    buttonSize = 'default',
    breakpoints,
    navigation = true,
    pagination = false,
    loop = true,
    grid,
    autoplay,
    ...props
}) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    let nextButtonClasses = cn(
        'w-7 h-7 md:w-8 md:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 text-base lg:text-lg xl:text-xl cursor-pointer flex items-center justify-center rounded-full bg-skin-fill absolute transition duration-300 hover:bg-skin-primary hover:text-skin-inverted focus:outline-none transform shadow-navigation',
        { '3xl:text-2xl': buttonSize === 'default' },
        nextButtonClassName
    );
    let prevButtonClasses = cn(
        'w-7 h-7 md:w-8 md:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 text-base lg:text-lg xl:text-xl cursor-pointer flex items-center justify-center rounded-full bg-skin-fill absolute transition duration-300 hover:bg-skin-primary hover:text-skin-inverted focus:outline-none transform shadow-navigation',
        { '3xl:text-2xl': buttonSize === 'default' },
        prevButtonClassName
    )
    return (
        <div
            className={`carouselWrapper relative ${className} ${pagination ? 'dotsCircle' : 'dotsCircleNone'
                }`}
        >
            <Swiper
                dir='ltr'
                modules={[Navigation, Autoplay, Pagination, Grid]}
                autoplay={autoplay}
                breakpoints={breakpoints}
                pagination={pagination}
                grid={grid}
                navigation={
                    navigation
                        ? {
                            prevEl: prevActivateId.length
                                ? `#${prevActivateId}`
                                : prevRef.current, // Assert non-null
                            nextEl: nextActivateId.length
                                ? `#${nextActivateId}`
                                : nextRef.current, // Assert non-null
                        }
                        : {}
                }
                {...props}
            >
                {children}
            </Swiper>
            {Boolean(navigation) && (
                <div
                    className={`flex items-center w-full absolute top-2/4 z-10 ${buttonGroupClassName}`}
                >
                    {prevActivateId.length > 0 ? (
                        <div className={prevButtonClasses} id={prevActivateId}>
                            {<IoIosArrowBack />}
                        </div>
                    ) : (
                        <div ref={prevRef} className={prevButtonClasses}>
                            {<IoIosArrowBack />}
                        </div>
                    )}

                    {nextActivateId.length > 0 ? (
                        <div className={nextButtonClasses} id={nextActivateId}>
                            {<IoIosArrowForward />}
                        </div>
                    ) : (
                        <div ref={nextRef} className={nextButtonClasses}>
                            {<IoIosArrowForward />}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Carousel