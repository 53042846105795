import cn from "classnames";
import { useSelector } from "react-redux";
import Alert from "../ui/alert";
import ProductCardLoader from "../ui/loaders/product-card-loader";
import ProductCard from "./ProductCard";

const ProductBundleGrid = ({
    element,
    className = '',
    data,
    error,
    isLoading
  }) => {
    const cart = useSelector((state) => state.cart)

    return (
      <div className={cn(className)}>
        {error ? (
          <Alert message={error?.message} />
        ) : (
          <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 gap-3 md:gap-4 2xl:gap-5">
            {isLoading ? (
              Array.from({ length: 30 }).map((_, idx) => (
                <ProductCardLoader
                  key={`product--key-${idx}`}
                  uniqueKey={`product--key-${idx}`}
                />
              ))
            ) : (
              <>
                {data?.slice().sort((a, b) => a.order - b.order).map((product) => {
                  return (
                    <ProductCard key={`product--key${product.id}`} item={product}/>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default ProductBundleGrid;