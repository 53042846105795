import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountLogoutIcon from "../../icons/account-logout";
import { FaChevronDown } from 'react-icons/fa';
import { useLogoutMutation } from "../../../services/slices/client";
import { useDispatch } from "react-redux";
import { clearCredentials } from "../../../store/slices/auth";

export default function AccountNavMobile({ options }) {
    
    const { pathname } = useLocation();
    const currentSelectedItem = pathname
    ? options.find((o) => o.slug === pathname)
    : options[0];
    
    const [selectedItem, setSelectedItem] = useState(currentSelectedItem);
    let navigate = useNavigate();
    useEffect(() => {
        setSelectedItem(currentSelectedItem);
      }, [currentSelectedItem]);

    function handleItemClick(slugs) {
        setSelectedItem(slugs);
        navigate(slugs.slug)
      }
    
    const [logout] = useLogoutMutation()
    const dispatch = useDispatch()

    async function signout(e){
        e.preventDefault()
        await logout()
        dispatch(clearCredentials())
        navigate('/')
    }
    return (
        <Listbox value={selectedItem} onChange={handleItemClick}>
      {({ open }) => (
        <div className="relative w-full font-body">
          <Listbox.Button className="text-skin-base relative w-full p-4 md:p-5 text-start  rounded focus:outline-none cursor-pointer border border-skin-base flex items-center">
            {selectedItem?.icon}
            <span className="flex truncate items-center text-sm md:text-15px font-medium ps-2.5 relative">
              {selectedItem?.name}
            </span>
            <span className="absolute inset-y-0 end-4 md:end-5 flex items-center pointer-events-none">
              <FaChevronDown
                className="w-3 md:w-3.5 h-3 md:h-3.5 text-skin-base text-opacity-70"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute z-20 w-full py-2.5 mt-1.5 overflow-auto bg-skin-fill rounded-md shadow-dropDown max-h-72 focus:outline-none text-sm md:text-15px"
            >
              {options?.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `cursor-pointer relative py-3 px-4 md:px-5 ${
                      active
                        ? 'text-skin-base bg-skin-dropdown-hover'
                        : 'text-skin-base'
                    }`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <span className="flex items-center">
                      {option?.icon}
                      <span
                        className={`block truncate ps-2.5 md:ps-3 ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option?.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${active && 'text-amber-600'}
                                 absolute inset-y-0 start-0 flex items-center ps-3`}
                        />
                      ) : null}
                    </span>
                  )}
                </Listbox.Option>
              ))}
              <button
                className="w-full flex items-center text-sm lg:text-15px text-skin-base py-3 px-4 md:px-5 cursor-pointer focus:outline-none"
                onClick={signout}
              >
                <span className="flex-shrink-0 flex justify-center">
                  <AccountLogoutIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
                </span>
                <span className="block truncate ps-2.5 md:ps-3">
                  Cerrar Sesión
                </span>
              </button>
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
    )
}