import Container from "../../components/ui/container";
import PageHeroSection from "../../components/ui/page-hero-section";
import { useGetPrivacyQuery } from "../../services/slices/misc"
import {Helmet} from "react-helmet";
const PrivacyPage = () => {
    const {
        data
    } = useGetPrivacyQuery({}, { refetchOnMountOrArgChange: true })
    return (
        <>
            <Helmet>
                <title>Política de Privacidad - Lysto</title>
            </Helmet>
            <PageHeroSection heroTitle="Política de Privacidad" />
            <div className="py-12 lg:py-16 2xl:py-20">

                <Container>
                    {data && <div className="w-full xl:max-w-[1200px] mx-auto" dangerouslySetInnerHTML={{ __html: data.privacy }} />}
                </Container>
            </div>

        </>
    )
}

export default PrivacyPage