import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    displayDrawer: false,
    drawerView: null,
    displayAlert: false,
    alertView: null,
    alertViewContent: null,
    displaySidebar: false,
    displayMobileSearch: false,
    displaySearch: false
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        // - closeDrawer
        closeDrawer: (state, action) => {
            state.displayDrawer = false
        },
        setDrawerView: (state, action) => {
            const { view } = action.payload
            state.drawerView = view
        },
        // - openDrawer
        openDrawer: (state, action) => {
            state.displayDrawer = true
        },

        setAlertView: (state, action) => {
            const { view, content } = action.payload
            state.alertView = view
            state.alertViewContent = content
        },

        showAlert: (state, action) => {
            state.displayAlert = true
        },
        hideAlert: (state, action) => {
            state.displayAlert = false
        },

        openSidebar: (state) => {
            state.displaySidebar = true
        },
        closeSidebar: (state) => {
            state.displaySidebar = false
        },
        openMobileSearch: (state) => {
            state.displayMobileSearch =  true
        },
        closeMobileSearch: (state) => {
            state.displayMobileSearch = false
        }

    }
})

export const { closeDrawer, setDrawerView, openDrawer, setAlertView, showAlert, hideAlert, openSidebar, closeSidebar, closeMobileSearch, openMobileSearch } = uiSlice.actions

export default uiSlice.reducer