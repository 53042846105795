import { useNavigate } from "react-router-dom"

const NavbarTop = () => {
    const navigate = useNavigate()
    const handleOffers = () => {
        navigate('/category/offers')
    }
    return (
        <div className="bg-zinc-100">
        <nav className="w-full px-4 md:px-6 lg:px-8 2xl:px-10 bg-zinc-100 mx-auto max-w-[1920px]">
            <ul className="flex content-center">
                <li className="text-sm inline-block py-2 px-4 bg-skin-primary text-skin-inverted font-bold">
                <button onClick={handleOffers}>OFERTAS</button></li>
            </ul>
            
        </nav>
        </div>
    )
}

export default NavbarTop