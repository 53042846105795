import cn from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "../../utils/slug";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

function SidebarMenuItem({ className, item, depth = 0 , active, setActive}) {
    const { name, subcategories: items, thumbNail } = item;
    let navigate = useNavigate();
    const isActive =
    active === item.slug ||
    item?.subcategories?.some((_item) => _item.id === active);

    const [isOpen, setOpen] = useState(isActive);

    function toggleCollapse() {
        setOpen((prevValue) => !prevValue);
      }
    
    function handleClick() {
        setActive(item.id)
        if (Array.isArray(items) && !!items.length) {
            toggleCollapse();
        } else {
            navigate(`/category/${item.id}`);
        }
        
    }
    useEffect(() => {
        setOpen(isActive);
      }, [isActive]);

      let expandIcon;
      if (Array.isArray(items) && items.length) {
        expandIcon = !isOpen ? (
          <IoIosArrowDown className="text-base text-skin-base text-opacity-40" />
        ) : (
          <IoIosArrowUp className="text-base text-skin-base text-opacity-40" />
        );
      }
    return (
        <>
            <li className={`flex justify-between items-center transition text-skin-base text-opacity-70 ${className
                ? className
                : 'text-sm md:text-15px hover:bg-skin-two border-t border-skin-base first:border-t-0 px-3.5 2xl:px-4'
                }`}>
                <button
                onClick={handleClick}
                    className={cn(
                        'flex items-center w-full text-start outline-none focus:outline-none focus:ring-0 focus:text-skin-base'
                    )}
                >
                    {thumbNail && (
            <div className="inline-flex flex-shrink-0 2xl:w-12 2xl:h-12 3xl:w-auto 3xl:h-auto">
              <img
                src={thumbNail ?? '/assets/placeholder/category-small.svg'}
                alt={name}
                width={40}
                height={40}
              />
            </div>
          )}
                    <span className="text-skin-base capitalize ps-2.5 md:ps-4 2xl:ps-3 3xl:ps-4">
                        {name}
                    </span>
                    <span className="ms-auto">{expandIcon}</span>
                </button>

            </li>
            {Array.isArray(items) && isOpen ? (
                <li>
                    <ul key="content" className="text-xs border-t border-skin-base py-1">
                        {items?.map((currentItem) => {
                        const childDepth = depth + 1;
                        return (
                            <SidebarMenuItem
                            key={`${slugify(item.name)}-key-${item.id}`}
                            item={currentItem}
                            depth={childDepth}
                            className={cn('text-sm ps-14 py-1.5 pe-4')}
                            active={active} setActive={setActive}
                            />
                        );
                        })}
                    </ul>
                </li>
            ) : null}
        </>
    )
}

function SidebarMenu({ items, className }) {
    const [active, setActive] = useState("")

    return (
        <ul className={cn(className)}>
            {items?.map((item) => (
                <SidebarMenuItem key={`${slugify(item.name)}-key-${item.id}`} item={item} active={active} setActive={setActive}/>
            ))}
        </ul>
    );
}

export default SidebarMenu;
