import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateAddresses: false,
}

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        toggleUpdateAddresses: (state, action) => {
            state.updateAddresses = !state.updateAddresses
        },

    }
})

export const { toggleUpdateAddresses } = addressSlice

export default addressSlice.reducer