import { Outlet } from "react-router-dom";
import HeaderSinglePage from "./singlePage/header";

function LayoutSinglePage(props) {
    return (
        <div className="flex flex-col min-h-screen">
            <HeaderSinglePage />
            <main
                className="relative flex-grow"
                style={{
                    WebkitOverflowScrolling: 'touch',
                }}
            >

                <Outlet />
            </main>
        </div>
    )
}


export default LayoutSinglePage;