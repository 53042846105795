import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cartLength: 0,
        productsQty: { },
        cartProducts: [],
    },
    reducers: {
        increaseItemQty: (state, action) => {
            const { item } = action.payload
            if (!(item.id  in state.productsQty)){
                state.cartProducts.push(item)
                state.productsQty[item.id] = 0
            }
            state.productsQty[item.id] += 1
            state.cartLength += 1
        },
        decreaseItemQty: (state, action) => {
            const { item } = action.payload
            state.productsQty[item.id] -= 1
            if (state.productsQty[item.id] === 0){
                delete state.productsQty[item.id]
                const index = state.cartProducts.findIndex(product => product.id === item.id)
                if (index !== -1) state.cartProducts.splice(index, 1)
            }
            state.cartLength -= 1
        },
        clearCart: (state) => {
            state.productsQty = {}
            state.cartLength = 0
            state.cartProducts = []
        },
        removeProduct: (state, action) => {
            const { item } = action.payload
            state.cartLength -= state.productsQty[item.id]
            const index = state.cartProducts.findIndex(product => product.id === item.id)
            if (index !== -1) state.cartProducts.splice(index, 1)
            delete state.productsQty[item.id]
        },

    }
})





export const { increaseItemQty, decreaseItemQty, clearCart, removeProduct, setCartFromRemote} = cartSlice.actions
export default cartSlice.reducer