import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { siteSettings } from "../../../settings/site-settings";
import { closeModal } from "../../../store/slices/modal";
import Button from "../../ui/button";
import CloseButton from "../../ui/close-button";
import Heading from "../../ui/heading";
import Map from "../../ui/map";
import {
    useJsApiLoader, useLoadScript
} from '@react-google-maps/api';
import { useState } from "react";
import { useAuth } from "../../../store/useAuth";
import { useAddAddressMutation } from "../../../services/slices/addresses";
import { useEffect } from "react";
import { toggleUpdateAddresses } from "../../../store/slices/address";
import Input from "../../ui/form/input";
import TextArea from "../../ui/text-area";

const ExtraFields = ({instrucciones, numero}) => {

    const onChangeNumber = (e) => {
        numero?.setNumero(e.target.value)
    }

    const onChangeInstructions = (e) => {
        instrucciones?.setInstructions(e.target.value)
    }

    return (
        <>
            <Input
                placeholder="Numero, Departamento, oficina, etc (opcional)"
                type="text"
                variant="solid"
                onChange={onChangeNumber}
                value={numero?.numero} />
            <TextArea
                placeholder="Instrucciones de entrega (opcional)"
                variant="solid"
                onChange={onChangeInstructions}
                value={instrucciones?.instructions}/>
        </>
    )
}


const AddAddressForm = () => {
    const auth = useAuth()
    const [addAddress, { data, isLoading, isSuccess, error }] = useAddAddressMutation()

    const [selectedMarker, setSelectedMarker] = useState(null)
    const [instructions, setInstructions] = useState("")
    const [numero, setNumero] = useState("")
    const setValue = (address) => {
        setSelectedMarker(address)
    }

    const dispatch = useDispatch()

    const onSubmit = () => {
        const params = {
            direccion: selectedMarker.formatted_address,
            lng: selectedMarker.geometry?.location?.lng(),
            lat: selectedMarker.geometry?.location?.lat(),
            numero: numero,
            instrucciones: instructions,
        }
        const uid = auth.user?.id
        addAddress({ uid, params })
        dispatch(closeModal())
        dispatch(toggleUpdateAddresses())
        
    }

    useEffect(() => {
        console.log(data, isLoading, isSuccess, error)
    }, [data, isLoading, isSuccess, error])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${siteSettings.maps.google_maps_key}`,
        libraries: ["places"],
        region: 'cl'

    });

    return (
        <div className="w-full md:w-[600px] lg:w-[900px] xl:w-[1000px] mx-auto p-5 sm:p-8 bg-skin-fill rounded-md">
            <CloseButton onClick={() => dispatch(closeModal())} />
            <Heading variant="title" className="mb-8 -mt-1.5">
                Agregar Dirección
            </Heading>

            <Map
                lat={-33.4429091}
                lng={-70.6538699}
                height={'420px'}
                zoom={13}
                isLoaded={isLoaded}
                showInfoWindow={false}
                mapCurrentPosition={(value) =>
                    setValue(value)
                }
                extra={<ExtraFields 
                    numero={{ numero, setNumero }}
                    instrucciones={{ instructions, setInstructions }} />}


            />

            <div className="flex w-full justify-end">
                <Button className="h-11 md:h-12 mt-1.5" type="submit" onClick={onSubmit} disabled={!selectedMarker}>
                    Guardar
                </Button>
            </div>
        </div>
    )
}

export default AddAddressForm