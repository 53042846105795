import { debounce } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from "react-router-dom";
import { saveState } from './store/local';
import store from './store/store';


import './styles/scrollbar.css'


import './styles/tailwind.css'
import './styles/swiper-carousel.css'
import './styles/custom-plugins.css'

import { firebaseConfig } from './settings/site-settings';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import TagManager from 'react-gtm-module'
import router from './routes';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://2f25d3491bde4336b7f6f0e6ebe93827@o4504316666445824.ingest.sentry.io/4504316707405824",
  environment: process.env.NODE_ENV.toLowerCase(),
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 800)
)

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-59N8CT8'
}
TagManager.initialize(tagManagerArgs)

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);