import cn from "classnames";
import { useRef, useState } from "react";
import {
    Swiper,
    SwiperSlide,
    Navigation,
    Thumbs,
} from '../carousel/slider';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// product gallery breakpoints
const galleryCarouselBreakpoints = {
    '0': {
        slidesPerView: 4,
    },
};

const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 0,
};

const ThumbnailCarousel = ({ gallery,
    thumbnailClassName = 'xl:w-[480px] 2xl:w-[650px]',
    galleryClassName = 'xl:w-28 2xl:w-[130px]',
}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <div className="w-full xl:flex xl:flex-row-reverse">
            <div
                className={cn(
                    'w-full xl:ms-5 mb-2.5 md:mb-3 border border-skin-base overflow-hidden rounded-md relative',
                    thumbnailClassName
                )}
            >
                <Swiper
                    id="productGallery"
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null  }}
                    modules={[Navigation, Thumbs]}
                    navigation={{
                        prevEl: prevRef.current, // Assert non-null
                        nextEl: nextRef.current, // Assert non-null
                    }}
                    {...swiperParams}
                >
                    {gallery?.map((item) => (
                        <SwiperSlide
                            key={`product-gallery-${item}`}
                            className="flex items-center justify-center"
                        >
                            <img
                                src={item}
                                alt={`Product gallery ${item}`}
                                width={650}
                                height={590}
                                className="rounded-lg"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="flex items-center justify-between w-full absolute top-2/4 z-10 px-2.5">
                    <div
                        ref={prevRef}
                        className="w-7 h-7 md:w-8 md:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 text-base lg:text-lg xl:text-xl flex items-center cursor-pointer justify-center rounded-full bg-skin-fill transition duration-300 hover:bg-skin-primary hover:text-skin-inverted focus:outline-none transform -translate-y-1/2 shadow-navigation"
                    >
                        <IoIosArrowBack />
                    </div>
                    <div
                        ref={nextRef}
                        className="w-7 h-7 md:w-8 md:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 text-base lg:text-lg xl:text-xl flex items-center justify-center cursor-pointer rounded-full bg-skin-fill  transition duration-300 hover:bg-skin-primary hover:text-skin-inverted focus:outline-none transform -translate-y-1/2 shadow-navigation"
                    >
                        <IoIosArrowForward />
                    </div>
                </div>

            </div>
            {/* End of product main slider */}

            <div className={`flex-shrink-0 ${galleryClassName}`}>
                <Swiper
                    id="productGalleryThumbs"
                    onSwiper={setThumbsSwiper}
                    spaceBetween={0}
                    watchSlidesProgress={true}
                    freeMode={true}
                    observer={true}
                    observeParents={true}
                    breakpoints={galleryCarouselBreakpoints}
                >
                    {gallery?.map((item) => (
                        <SwiperSlide
                            key={`product-thumb-gallery-${item}`}
                            className="flex items-center justify-center cursor-pointer rounded overflow-hidden border border-skin-base transition hover:opacity-75"
                        > 
                            <img
                            
                                src={item}
                                alt={`Product thumb gallery ${item}`}
                                width={170}
                                height={170}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default ThumbnailCarousel