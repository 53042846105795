import Logo from "../../../../components/ui/logo"
import Text from "../../../../components/ui/text"
import { siteSettings } from "../../../../settings/site-settings"

const WidgetAbout = ({ social, className }) => {
    return (
        <div className={`pb-10 sm:pb-0 ${className}`}>
            <div className="flex flex-col text-center sm:text-start max-w-[300px] mx-auto sm:ms-0 pb-6 sm:pb-5">
                <Logo href="/" className="mb-3 lg:mb-5 mx-auto sm:ms-0" />
                <Text>{siteSettings.description}</Text>
            </div>

            {social && (
                <ul className="flex flex-wrap justify-center sm:justify-start space-s-4 md:space-s-5 mx-auto md:mx-0">
                    {social?.map((item) => (
                        <li
                            className="transition hover:opacity-80"
                            key={`social-list--key${item.id}`}
                        >
                            <a href={item.path ? item.path : '/#'} target="_blank" rel="noreferrer">

                                <img
                                    src={item.image}
                                    alt={item.name}
                                    height={item.height}
                                    width={item.width}
                                    className="transform scale-85 md:scale-100"
                                />

                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default WidgetAbout