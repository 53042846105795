import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressGrid from "../../../components/address/address-grid";
import Heading from "../../../components/ui/heading";
import AccountLayout from "../../../components/User/AccountLayout";
import { useGetAddressQuery } from "../../../services/slices/client";
import { toggleUpdateAddresses } from "../../../store/slices/address";
import { useAuth } from "../../../store/useAuth";

function AddressGridPage() {
    const auth = useAuth()
    const dispatch = useDispatch()
    const addresses = useSelector((state) => state.address)

    const { data, isLoading, refetch } = useGetAddressQuery(auth.user?.id, {
        refetchOnMountOrArgChange: true
      })
    
    useEffect(()=>{
        if (addresses.updateAddresses){
            refetch()
            dispatch(toggleUpdateAddresses())
        }
        console.log(data)

    },[addresses,data])
    return (
        <AccountLayout>
            <Heading variant="titleLarge" className="mb-5 md:mb-6 lg:mb-7 lg:-mt-1">
                Mis direcciones
            </Heading>
            {isLoading ? <h3>Cargando</h3> : <AddressGrid address={data} default_address={auth.user?.id_direccion_default}/>}
        </AccountLayout>
    )
}

export default AddressGridPage