import Container from "../ui/container"
import Divider from "../ui/divider"

const CategoryHeroSection = ({
  backgroundThumbnail = '/assets/images/hero/fruits-juice.png',
  heroTitle,
  heroDescription,
}) => {
  return (
    <div
      className="flexitems-start"
    >
      <div className="max-w-[540px] flex flex-col relative">
        <h2 className="text-2xl lg:text-3xl 2xl:text-[40px] 2xl:leading-[1.3em] font-bold text-skin-base font-manrope pb-2">
          {heroTitle}
        </h2>
        {Boolean(heroDescription) && <p className="text-15px lg:text-base 2xl:text-[17px] leading-7 lg:leading-8 text-skin-base text-opacity-70">
          {heroDescription}
        </p>}
        
      </div>

    </div>
    )
}

export default CategoryHeroSection