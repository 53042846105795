import { Link } from "react-router-dom";
import Heading from "../../../../components/ui/heading";

const WidgetLink = ({ className, data }) => {
    const { widgetTitle, lists } = data;
    return (
        <div className={`${className}`}>
      <Heading variant="mediumHeading" className="mb-4 sm:mb-5 lg:mb-6 pb-0.5">
        {widgetTitle}
      </Heading>
      <ul className="text-sm lg:text-15px flex flex-col space-y-3">
        {lists.map((list) => (
          <li
            key={`widget-list--key${list.id}`}
            className="flex items-baseline"
          >
            {list.icon && (
              <span className="me-3 relative top-0.5 lg:top-1 text-sm lg:text-base">
                {list.icon}
              </span>
            )}

            <Link
              to={list.path ? list.path : '#!'}
              className="transition-colors duration-200 hover:text-skin-base"
            >
              {list.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
    )
}

export default WidgetLink