import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"
import CartButton from "../../components/Cart/cart-button"
import Search from "../../components/common/search"
import UserIcon from "../../components/icons/user-icons"
import Container from "../../components/ui/container"
import Logo from "../../components/ui/logo"
import AuthMenu from "../../components/User/AuthMenu"
import { useAuth } from "../../store/useAuth"
import { ROUTES } from "../../utils/routes"
import { openModal } from "../../store/slices/modal"
import NavbarTop from "./header/menu-top"
function Header(props) {
    const auth = useAuth()
    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const handleLogin = () => {
        dispatch(openModal({view:'LOGIN_VIEW'}))
    }

    return (
        <header id='siteHeader'
            className={cn(
                'header-two sticky-header sticky top-0 z-20 w-full h-auto',
                ui.displayMobileSearch && 'active-mobile-search'
            )}>
            <div className="innerSticky w-screen lg:w-full transition-all duration-200 ease-in-out body-font bg-skin-secondary z-20">
                <Search
                    searchId="mobile-search"
                    className="top-bar-search hidden lg:max-w-[600px] absolute z-30 px-4 md:px-6 top-1"
                />
                {/* End of Mobile search */}
                <Container className="top-bar h-16 lg:h-auto flex items-center justify-between py-3">
                    <Logo className="logo -mt-1.5 md:-mt-1" />
                    
                    <Search
                        searchId="top-bar-search"
                        className="hidden lg:flex lg:max-w-[650px] 2xl:max-w-[800px] lg:ms-8 lg:me-5"
                    />
                    <div className="flex flex-shrink-0 space-s-5 xl:space-s-7">

                        <div className="hidden lg:flex items-center flex-shrink-0 ">
                            <UserIcon className="text-skin-base text-opacity-40" />
                            <AuthMenu
                                isAuthorized={auth.user}
                                href={ROUTES.ACCOUNT}
                                btnProps={{
                                    children: "Ingresa",
                                    onClick: handleLogin,
                                }}>
                                Hola {auth.user?.username}
                            </AuthMenu>
                        </div>
                        <CartButton className="hidden lg:flex" />
                    </div>
                </Container>

            </div>
            <NavbarTop/>
        </header>
    )
}
export default Header