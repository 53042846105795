import cn from "classnames";
import useWindowSize from "../../utils/use-window-size";
import SectionHeader from "../common/section-header";
import Carousel from "../ui/carousel/carousel";
import { SwiperSlide } from '../ui/carousel/slider'
import ProductCardLoader from '../ui/loaders/product-card-loader'
import SeeAll from "../ui/see-all";
import ProductCard from './ProductCard'

const breakpoints = {
    '1921': {
        slidesPerView: 5,
    },
    '1780': {
        slidesPerView: 5,
    },
    '1536': {
        slidesPerView: 5,
    },
    '1280': {
        slidesPerView: 4,
    },
    '1024': {
        slidesPerView: 4,
    },
    '640': {
        slidesPerView: 3,
    },
    '360': {
        slidesPerView: 2,
    },
    '0': {
        slidesPerView: 1,
    },
};

const ProductsCarousel = ({
    sectionHeading,
    categorySlug,
    className = 'mb-8 lg:mb-10 xl:mb-12',
    products,
    loading,
    error,
    limit = 5,
    uniqueKey,
    carouselBreakpoint
}) => {
    const { width } = useWindowSize();

    return (
        <div
            className={cn(
                'max-w-[1920px] overflow-hidden 4xl:overflow-visible px-4 md:px-6 lg:px-8 2xl:ps-10 2xl:pe-0 4xl:pe-10 mx-auto relative',
                className
            )}
        >
            <div className="flex justify-between items-center flex-wrap mb-5 md:mb-6 pt-2">
                <SectionHeader sectionHeading={sectionHeading} className="mb-0" />
            </div>
            <div className="heightFull xl:-me-40 2xl:-me-28 4xl:me-0 relative after-item-opacity">
                <Carousel
                    breakpoints={carouselBreakpoint || breakpoints}
                    className="-mx-1.5 md:-mx-2 xl:-mx-2.5 -mt-4"
                    prevButtonClassName="w-7 h-7 md:w-8 md:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 text-base lg:text-lg xl:text-xl cursor-pointer flex items-center justify-center rounded-full bg-skin-fill absolute transition duration-300 hover:bg-skin-primary hover:text-skin-inverted focus:outline-none transform shadow-navigation -left-3.5 lg:-left-4 xl:-left-5.5"
                    nextButtonClassName={'w-7 h-7 md:w-8 md:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 text-base lg:text-lg xl:text-xl cursor-pointer flex items-center justify-center rounded-full bg-skin-fill absolute transition duration-300 hover:bg-skin-primary hover:text-skin-inverted focus:outline-none transform shadow-navigation -right-3.5 lg:-right-4 xl:-right-5'}
                >
                    {loading && !products?.length ? (
                        Array.from({ length: limit }).map((_, idx) => (
                            <SwiperSlide
                                key={`${uniqueKey}-${idx}`}
                                className="px-1.5 md:px-2 xl:px-2.5 py-4"
                            >
                                <ProductCardLoader uniqueKey={`${uniqueKey}-${idx}`} />
                            </SwiperSlide>
                        ))
                    ) : (
                        <>
                            {products.slice(0,13)?.map((product, idx) => (
                                <SwiperSlide
                                    key={`${uniqueKey}-${idx}`}
                                    className="px-1.5 md:px-2 xl:px-2.5 py-4"
                                >
                                    <ProductCard item={product} />
                                </SwiperSlide>
                            ))}
                            {/* <SwiperSlide className="p-2.5 flex items-center justify-center">
                                <SeeAll href={categorySlug} />
                            </SwiperSlide> */}
                            {width > 1024 && width < 1921 && <SwiperSlide />}
                        </>
                        )}
            </Carousel>
        </div>
        </div >
    )
}

export default ProductsCarousel