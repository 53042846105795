import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import CheckoutCard from "../components/checkout/checkout-card";
import CheckoutDetails from "../components/checkout/checkout-details";
import Container from "../components/ui/container";
import Divider from "../components/ui/divider";
import { useAuth } from "../store/useAuth";


export default function CheckoutPage() {
    const auth = useAuth()
    
    useEffect(()=>{
        window.fbq('track', 'InitiateCheckout')
    }, [])

    if (!auth.user) {
        return <Navigate to={'/'} replace />
    }
    return (
        <>
            <Container className="py-10 2xl:py-12 border-t border-skin-base checkout">
                <div className="flex xl:max-w-screen-xl mx-auto flex-col">
                    <div className="flex flex-col lg:grid lg:grid-cols-12 grid-cols-1 flex-wrap gap-8">
                        <div className="w-full col-start-1 col-end-9">
                        <CheckoutDetails />
                        
                        </div>
                        <div className="w-full mt-7 lg:mt-0 col-start-9 col-end-13">
                            <CheckoutCard/>
                        </div>
                    </div>
                </div>
            </Container>
            <Divider />
        </>
    )
}