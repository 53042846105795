import { Tab } from "@headlessui/react";
import classNames from "classnames";


export default function ProductDetailsTab({details}) {
    return (
    <div className="w-full xl:px-2 py-11 lg:py-14 xl:py-16 sm:px-0">
        <Tab.Group>
        <Tab.List className="block border-b border-skin-base space-s-8">
            <Tab
              key={'descripcion'}
              className='relative inline-block transition-all text-15px lg:text-17px leading-5 text-skin-base focus:outline-none pb-3 lg:pb-5 hover:text-skin-primary font-semibold'
            >
              Descripción
            </Tab>
        </Tab.List>
        <Tab.Panels className="mt-6 lg:mt-9">
          <Tab.Panel className="lg:flex">
            <div className="text-sm sm:text-15px text-skin-muted leading-[2em] space-y-4 lg:space-y-5 xl:space-y-7">
              <p>
                {details}
              </p>
            </div>
          </Tab.Panel>
        </Tab.Panels>
        </Tab.Group>
    </div>
    )
}