import { Navigate } from "react-router-dom"
import { useAuth } from "../../store/useAuth"
import AccountAddressIcon from "../icons/account-address"
import AccountOrderIcon from "../icons/account-order"
import AccountCardIcon from "../icons/account-payments"
import AccountSettingsIcon from "../icons/account-settings"
import Container from "../ui/container"
import AccountNav from "./my-account/account-nav"
import AccountNavMobile from "./my-account/account-nav-mobile"
import { Helmet } from "react-helmet";

const accountMenu = [
    {
        slug: '/mi-cuenta/datos',
        name: 'Mis datos',
        icon: <AccountSettingsIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
    },
    {
        slug: '/mi-cuenta/direcciones',
        name: 'Mis direcciones',
        icon: <AccountAddressIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
    },
    {
        slug: '/mi-cuenta/pedidos',
        name: 'Mis pedidos',
        icon: <AccountOrderIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />

    },
    {
        slug: '/mi-cuenta/medios-pago',
        name: 'Mis tarjetas',
        icon: <AccountCardIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />

    }
]

const AccountLayout = (props) => {
    const auth = useAuth()
    if (!auth.user) {
        return <Navigate to={'/'} replace />
    }
    return (

        <div className="border-t border-b border-skin-base">
            <Helmet>
                <title>Cuenta - Lysto</title>
            </Helmet>
            <Container >
                <div className="pt-10 2xl:pt-12 pb-12 lg:pb-14 xl:pb-16 2xl:pb-20 xl:max-w-screen-xl 2xl:max-w-[1300px] mx-auto">
                    <div className="flex flex-col lg:flex-row w-full">
                        <div className="lg:hidden">
                            <AccountNavMobile options={accountMenu} />
                        </div>
                        <div className="hidden lg:block flex-shrink-0 w-80 xl:w-[385px] me-7 xl:me-8">
                            <AccountNav options={accountMenu} />
                        </div>
                        <div className="w-full mt-4 lg:mt-0 border border-skin-base p-4 sm:p-5 lg:py-8 2xl:py-10 lg:px-9 2xl:px-12 rounded-md">
                            {props.children}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AccountLayout