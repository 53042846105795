import { useGetProductByIdQuery } from "../../../services/slices/product";
import ProductsCarousel from "../products-carousel";

const RelatedProductFeed = ({
    idRelated,
    carouselBreakpoint,
    className,
    uniqueKey = 'related-product-popup',
  }) => {
    const { data, error, isLoading } = useGetProductByIdQuery(idRelated) 
 
    return (
        <ProductsCarousel
          sectionHeading="Productos Relacionados"
          categorySlug="/search"
          className={className}
          products={data?.relacionados}
          loading={isLoading}
          limit={15}
          uniqueKey={uniqueKey}
          carouselBreakpoint={carouselBreakpoint}
        />
      );
  }

  export default RelatedProductFeed;
