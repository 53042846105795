import { lystoApi } from "../config"

export const productApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    getProductById: build.query({
      query: (id) => `producto/${id}`,
    }),
    getCategories: build.query({
      query: () => 'ProductoCategoria',
    }),
    getOffers: build.query({
      query: () => 'productocategoria/0/subcategories'
    })
  }),
  overrideExisting: false,
})

export const { useGetProductByIdQuery, useGetCategoriesQuery, useGetOffersQuery } = productApi