import React from "react";
import { useParams } from "react-router-dom";
import ProductSingleDetails from "../../components/Products/ProductSingleDetails";
import Container from "../../components/ui/container";
import { useGetProductByIdQuery } from "../../services/slices/product";

function Product() {
    const { id } = useParams()
    const { data, isLoading } = useGetProductByIdQuery(id, {
        refetchOnMountOrArgChange: true
      })    
    return (
        <>
        <Container>
            {isLoading ? <h3>Cargando...</h3> :
                <ProductSingleDetails data={data}/>}
        </Container>
        </>
    );
}

export default Product;