import { Outlet, redirect } from "react-router-dom"
import ManageAlert from "../../components/alert/ManageAlert"
import ManagedDrawer from "../../components/common/managed-drawer"
import ManagedModal from "../../components/common/managed-modal"
import MobileNavigation from "../../components/layout/mobile-navigation/mobile-navigation"
import Footer from "./footer/footer"
import Header from "./header"
import { ScrollRestoration } from "react-router-dom";

import { FloatingWhatsApp } from 'react-floating-whatsapp'

import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleActiveCallback } from "../../store/slices/callback"

function LayoutPage(props) {
  
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main
        className="relative flex-grow"
        style={{
          WebkitOverflowScrolling: 'touch',
        }}
      >
              

        <ManageAlert/>
        <Outlet />
      </main>
      <Footer />
      <MobileNavigation />
      <ManagedDrawer/>
      <ManagedModal/>
      <FloatingWhatsApp
      phoneNumber="56957851582"
      chatMessage="Bienvenido a Lysto, en que te podemos ayudar?"
      accountName="Lysto"
      statusMessage="Disponible"
      placeholder="Escríbenos"
      allowClickAway={true}
      avatar="/assets/images/perfil_wsp.jpeg"
      buttonStyle={{background: '#02B290', bottom:'3rem', right: '3rem'}}
      notification={false}
      style={{animation: 'none'}}
    />
    <ToastContainer />
    <ScrollRestoration/>
    </div>

  )
}
export default LayoutPage