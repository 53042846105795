import { useGetAddressQuery } from "../../services/slices/client";
import { useAuth } from "../../store/useAuth";
import AddressGrid from "../address/address-grid";

const AddressPage = () => {
    const auth = useAuth()
    const { data, isLoading } = useGetAddressQuery(auth.user?.id)

    return !isLoading ? (
      <AddressGrid address={data} default_address={auth.user?.id_direccion_default} />
    ) : (
      <div>Loading...</div>
    );
  };

  export default AddressPage;
