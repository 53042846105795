import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import CategoryHeroSection from "../../components/category/category-hero-section";
import ProductBundleGrid from "../../components/Products/ProductBundleGrid";
import Container from "../../components/ui/container";
import Divider from "../../components/ui/divider";
import { useSearchMutation } from "../../services/slices/misc";
import { Helmet } from "react-helmet";

function SearchResults() {
    const [searchParams] = useSearchParams()
    const searchQuery = searchParams.get('q')
    const [search, { data, isLoading, error }] = useSearchMutation()
    let navigate = useNavigate();
    useEffect(() => {
        if (searchQuery) {
            search({ term: searchQuery })
        } else {
            navigate('/')
        }
    }, [searchQuery])

    return (
        <>
            <Container>
                <Helmet>
                    <title>Busqueda: {searchQuery} - Lysto</title>
                </Helmet>
                <Breadcrumbs items={[{ name: 'Busqueda', url: `/search?q=${searchQuery}` },]} />
                <CategoryHeroSection heroTitle={searchQuery} />
                <Divider />

                <div className="py-3">
                    {isLoading ? <p>buscando...</p>
                        : <><p>Mostrando {data?.productos.length} productos</p>

                            <ProductBundleGrid
                                className="pt-3" data={data?.productos} error={error} isLoading={isLoading} />
                        </>
                    }
                </div>
            </Container>

        </>
    )
}

export default SearchResults;