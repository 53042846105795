import cn from "classnames"
import { Link } from "react-router-dom"
import useWindowSize from "../../utils/use-window-size";

function getImage(deviceWidth, imgObj) {
    //return deviceWidth < 480 ? imgObj.mobile : imgObj.desktop;
    return imgObj
  }



const getUrlLink = (banner) => {
  const entities = {
    'ProductoCategoria': 'category',
    'Producto': 'product',
    'Marca': 'brand'
  }
  
  let url = '/'
  if (banner.entity in entities){
    url+= `${entities[banner.entity]}/${banner.entityId}`
  }
  return url
}

const BannerCard = ({
    banner,
    className,
    variant = 'default',
    effectActive = true,
    classNameInner,
}) => {
    const { width } = useWindowSize();
    const { slug, description } = banner;
    const selectedImage = getImage(width, banner);
    return (
        <div className={cn('mx-auto', className)}>
      <Link
        to={getUrlLink(banner)}
        className={cn(
          'h-full group flex justify-center relative overflow-hidden',
          classNameInner
        )}
      >
        <img
          src={selectedImage.url}
          width={selectedImage.width}
          height={selectedImage.height}
          alt={description}
          quality={100}
          className={cn('bg-skin-thumbnail object-cover w-full', {
            'rounded-md': variant === 'rounded',
          })}
        />
        {effectActive && (
          <div className="absolute top-0 -start-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-30 group-hover:animate-shine" />
        )}
      </Link>
    </div>
    )
}

export default BannerCard